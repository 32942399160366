export default class DownloadStatus {
  static UNSET = new DownloadStatus('UNSET')
  static REQUESTED = new DownloadStatus('REQUESTED')
  static PREPARING = new DownloadStatus('PREPARING')
  static PREPARED = new DownloadStatus('PREPARED')
  static REJECTED = new DownloadStatus('REJECTED')
  static PROCESSING = new DownloadStatus('PROCESSING')
  static FINISHING = new DownloadStatus('FINISHING')
  static COMPLETE = new DownloadStatus('COMPLETE')
  static PROCESSING_ERROR = new DownloadStatus('PROCESSING_ERROR')
  static WORKER_ERROR = new DownloadStatus('WORKER_ERROR')
  static DOWNLOADED = new DownloadStatus('DOWNLOADED')

  constructor(name) {
    this.name = name
  }
}
