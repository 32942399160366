const localStorageKey = 'previzSettings'

const state = {
  defaultMap: {
    src: '/img/assets/checkerboard.png',
    type: 'image'
  },
  loadingStepTimeout: 5000,
  tweenCamera: false,
  assetsTab: 'list-view', // 'list-view', 'grid-view'
  timelineJumpInSeconds: 10,
  timelineSecondJumpDelay: 1,
  timelineNextJumpsDelay: 0.5
}

const mutations = {
  setTweenCamera(state, { tweenCamera }) {
    state.tweenCamera = tweenCamera
    storeSettings(state)
  },
  restoreSettings(state) {
    let d = window.localStorage.getItem(localStorageKey)
    if (!d) {
      return
    }
    d = JSON.parse(d)
    if (d.hasOwnProperty('tweenCamera')) {
      state.tweenCamera = d.tweenCamera
    }
    if (d.hasOwnProperty('assetsTab')) {
      state.assetsTab = d.assetsTab
    }
  },
  setAssetsTab(state, { assetsTab }) {
    state.assetsTab = assetsTab
    storeSettings(state)
  }
}

const actions = {
  setAssetsTab({ commit }, { assetsTab }) {
    commit('setAssetsTab', { assetsTab })
  }
}

export default {
  state,
  mutations,
  actions
}

function storeSettings(state) {
  let settings = {
    tweenCamera: state.tweenCamera,
    assetsTab: state.assetsTab
  }
  window.localStorage.setItem(localStorageKey, JSON.stringify(settings))
}
