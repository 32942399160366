import { updateInListByKey } from '@utils/misc.js'

const state = {
  members: [],
  teamAssociations: [],
  projectAssociations: [],

  loaded: false,
  loading: false,
  loadingError: false
}

const getters = {
  members(state) {
    return state.members
  },

  getMemberById: (state) => (id) => {
    let found = state.members.find((row) => {
      return row.id === id
    })

    if (found === undefined) return null
    return found
  },

  loading(state) {
    return state.loading
  },

  loaded(state) {
    return state.loaded
  },

  loadingError(state) {
    return state.loadingError
  }
}

const actions = {
  addMembers({ commit }, { members }) {
    // console.log('MEMBERS VUEX - adding member', members)
  },

  setMembers({ commit, state }, { members }) {
    commit('setMembers', { state, members })
  },

  addMember({ commit }, member) {
    commit('addMember', member)
  },

  setLoading({ commit, state }) {
    commit('setLoading', state)
  },

  setLoadingError({ commit, state }) {
    commit('setLoadingError', state)
  },

  setLoaded({ commit, state }) {
    commit('setLoaded', state)
  }
}

const mutations = {
  setTeams(state, { teams }) {
    state.teams = teams
    state.teamsLoaded = true
    state.teamsLoading = false
    state.teamsLoadingError = false
  },
  setTeamsLoaded(state) {
    state.teamsLoaded = true
    state.teamsLoading = false
    state.teamsLoadingError = false
  },
  setTeamsLoading(state) {
    state.teamsLoaded = false
    state.teamsLoading = true
    state.teamsLoadingError = false
  },
  setTeamsLoadingError(state) {
    state.teamsLoaded = false
    state.teamsLoading = false
    state.teamsLoadingError = true
  },
  addTeam(state, team) {
    state.teams = updateInListByKey(state.teams, 'id', team.id, team)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
