import KeyboardBinding from '@utils/keyboardBinding'
import KeyboardComponent from '@src/enums/keyboardComponent'
import KeyboardBindingLevel from '@src/enums/keyboardBindingLevel'

/**
 * This mixin is available globally. It acts as the interface
 * to the underlying keyboard binding object.
 * To use this mixin, add a `keyboardComponent` data property to the component.
 * The `keyboardComponent` data property should be a value from the `KeyboardComponent` enum.
 */
export const keyboardShortcuts = {
  data() {
    return {
      keyboardBinding: KeyboardBinding,
      keyboardComponent: KeyboardComponent.UNSET
    }
  },

  methods: {
    /**
     *
     * @param {String} key - The hard key to bind to
     * @param {String} userKey - An additional key to bind to
     */
    $addGlobalUserMapToKey(key, userKey) {
      // Combine the new key with the existing keys and map them
      const existingMappings = this.keyboardBinding.globalBindings[
        key
      ].userMapping.concat([userKey])

      this.keyboardBinding.setUserMapping({
        key,
        userMap: existingMappings,
        bindingLevel: KeyboardBindingLevel.GLOBAL
      })
    },

    /**
     * Binds a keyboard shortcut to a function, for global keys.
     * @param {String} key - The key to bind to
     * @param {Function} callback - The callback to call when the key is pressed
     */
    $bindGlobalKey(key, callback) {
      this.keyboardBinding.bindGlobalKey({
        key,
        callback
      })
    },

    /**
     * Binds the passed key to the passed callback, using the component's `keyboardComponent` data property.
     * @param {String} key - The key to bind to. Should be the hard value of the key, not the user mapped key.
     * @param {Function} callback - The callback to call when the key is pressed.
     */
    $bindKeyboardKey(key, callback) {
      this.keyboardBinding.bindKey({
        key,
        callback,
        component: this.keyboardComponent
      })
    },

    /**
     * Removes the passed key from the global keyboard bindings.
     * @param {String} key - The key to unbind. Should be the hard value of the key, not the user mapped key.
     */
    $unbindGlobalKey(key) {
      this.keyboardBinding.unbindGlobalKey(key)
    },

    /**
     * Unbinds all keyboard shortcuts that aren't global. This is useful when specifically
     * unbinding a component's keyboard shortcuts.
     */
    $unbindComponentKeyboardKeys() {
      this.keyboardBinding.unbindComponentKeyboardKeys(this.keyboardComponent)
    }
  },

  computed: {
    $isComponentActive() {
      if (this.keyboardComponent === KeyboardComponent.UNSET) {
        return false
      }
      return this.keyboardComponent === this.keyboardBinding.activeComponent
    }
  }
}
