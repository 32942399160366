import { cloneDeep } from 'lodash'
import { findInListByKey } from './misc'
import FolderType from '@src/enums/folderType'

/**
 * Creates a folder tree item from the given folder object. Creates defaults if
 * not passed
 * @param {Object} payload - The payload to construct the the folderTree item
 * @returns {Object} - The constructed folderTree item
 */
export function generateFolderTreeStructure(payload) {
  return {
    id: payload.id,
    label: payload.label || '',
    expanded: 'expanded' in payload ? payload.expanded : true,
    seen: 'seen' in payload ? payload.seen : false,
    isLocked: 'isLocked' in payload ? payload.isLocked : false,
    permissions: payload.permissions || [],
    nextLink: payload.nextLink || '',
    children: payload.children || []
  }
}

/**
 * Recursively walk down the folderTree until we find the passed folderId
 * @param {Object} folderTree - The folder tree to walk down
 * @param {String} folderId - The folderId to find
 * @param {FolderType} returnItem - The item to return if we find the folderId
 * @returns {Object} - The folder object
 */
export function walkFolderTree({
  folderTree,
  folderId,
  returnItem = FolderType.FOLDER
}) {
  // First check if this level is the folder we are searching for
  if (folderTree.id === folderId) {
    return folderTree
  } else if (folderTree.children) {
    // Failsafe to ensure this level has children (it always should)

    // First see if this level has a child with the folderId and return if so
    // * NOTE: Only returnItem options are FOLDER and PARENT right now,
    // * but made the option an enum for future expansion (change the below to a switch/case if so)
    const existingChild = findInListByKey(folderTree.children, 'id', folderId)
    if (existingChild)
      return returnItem === FolderType.FOLDER ? existingChild : folderTree

    // Nope? We must go deeper
    for (const child in folderTree.children) {
      // For each child, recursively walk down its tree
      const childTree = walkFolderTree({
        folderTree: folderTree.children[child],
        folderId,
        returnItem
      })
      if (childTree != null) {
        // Is the child tree the folder we are looking for?
        if (childTree.id === folderId)
          return returnItem === FolderType.FOLDER ? childTree : folderTree

        // Nope, let's check its children
        const existingChild = findInListByKey(
          childTree.children,
          'id',
          folderId
        )
        if (existingChild)
          return returnItem === FolderType.FOLDER ? existingChild : childTree
      }
    }
  } else {
    return null
  }
}

/**
 * Gets an array of all children IDs for the passed folderId
 * @param {Object} folderTree - The folder tree to walk down
 * @param {String} folderId - The folderId to list all children of
 * @returns {Array} - The list of all children IDs, (including nested folders) flattened
 */
export function getAllChildrenForFolderId(folderTree, folderId) {
  function walk(folder) {
    if (folder && folder.children) {
      folder.children.forEach((child) => {
        children.push(child.id)
        walk(child)
      })
    }
  }

  const children = []
  walk(walkFolderTree({ folderTree, folderId }))
  return children
}

/**
 * Returns the file type based on the file extension / mime type
 * @param {File} file - The file object
 * @param {Object} extensions - The list of extensions, called from this.$store.getters['fileTypes/getFileTypes'].data
 * Note: this function is used in the store, so it's generally best to call the getter independently
 * @param {String} extension - The file extension, if known
 * @returns {String} - The file type
 */
export function getAssetType(file, extensions, extension = null) {
  let type = 'file'
  if (file.type.includes('image/')) type = 'image'
  else if (file.type.includes('video/')) type = 'video'
  else if (file.type.includes('audio/')) type = 'audio'
  else {
    // file.type doesn't get passed when coming through in a folder/sub-folder
    if (extensions[extension] !== undefined) {
      type = extensions[extension]
    }
  }

  return type
}

/**
 * Returns an object that resembles the asset object, but is using the version's
 * properties such as name, src, etc.
 * NOTE: This is not a 1:1 proxy, only the properties that are needed are proxied
 * if you need the full asset object, you'll need to fetch it from the API
 * or extend this function
 * @param {*} asset - The asset object
 * @param {*} versionId - The version uuid, must be a version of the asset, if no version is passed, the asset's current version is used
 */
export function proxyVersionAsAsset(asset, versionId) {
  const proxyAsset = cloneDeep(asset)

  const assetVersion = proxyAsset.versions.find(
    (assetVersion) => assetVersion.id === versionId
  )

  if (!assetVersion) {
    return asset
  }

  // NOTE: Add more to this as needed
  proxyAsset.version_id = assetVersion.id
  proxyAsset.version_number = assetVersion.version_number
  proxyAsset.thumbnail = assetVersion.thumbnail
  proxyAsset.url = assetVersion.url
  proxyAsset.status = assetVersion.status

  if (proxyAsset.type === 'image') {
    if (!('images' in proxyAsset.variant_urls)) {
      // This happens when you upload a new asset, the backend doesn't return the variant_urls
      // until after processing is complete, so we need to create it here
      // (obviously we don't have the other variants, so this isn't going to be as optimized for all screen sizes)
      proxyAsset.variant_urls['images'] = {
        tiny: assetVersion.url,
        thumb: assetVersion.url,
        small: assetVersion.url,
        medium: assetVersion.url,
        massive: assetVersion.url,
        large: assetVersion.url
      }
    } else {
      proxyAsset.variant_urls['images'] = Object.keys(
        proxyAsset.variant_urls['images']
      ).reduce((acc, key) => {
        acc[key] = assetVersion.url
        return acc
      }, {})
    }
  }

  return proxyAsset
}
