import Echo from 'laravel-echo'
import store from './store'
window.Pusher = require('pusher-js')

window.EchoInit = function () {
  echoInit()
}

const echoInit = () => {
  if (!store.getters['auth/isAuthedAndReady']) {
    window.Echo = false
    return
  }

    let auth = {
      headers: {
        Authorization: 'Bearer ' + store.getters['auth/access_token'],
        Accept: 'application/json'
      }
    }

    window.Echo = new Echo({
      broadcaster: 'pusher',
      authEndpoint: process.env.VUE_APP_SERVER_API_BASE + 'api-ws/broadcasting/auth',
      auth: auth,
      disableStats: true,
      key: process.env.VUE_APP_PUSHER_KEY,
      cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      encrypted: process.env.VUE_APP_PUSHER_ENCRYPTED,
      namespace: process.env.VUE_APP_PUSHER_NAMESPACE
    })
}

export default echoInit
