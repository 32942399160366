export const commandBar = {
  computed: {
    userId() {
      if (this.user) return this.user.id
      return null
    },
    isCommandBarBooted() {
      return window.CommandBar.shareState().isBooted || false
    }
  },
  watch: {
    userId: {
      handler(userId) {
        this.toggleCommandBarInit(userId)
      },
      immediate: true
    }
  },
  methods: {
    toggleCommandBarInit() {
      if (this.isCommandBarBooted || this.userId === null) {
        this.shutdownCommandBar()
      } else if (!this.isCommandBarBooted && this.userId) {
        this.bootCommandBar()
      }
    },
    bootCommandBar(userId) {
      window.CommandBar.boot(userId)
      this.addCommandBarCommands()
      this.addCommandBarCallbacks()
      this.loadCommandBarMetadata()

      window.CommandBar.removeCommand('app_langauge_preference')
    },

    loadCommandBarMetadata() {
      // These are essentially computed properties and are reactive
      // Metadata stays local. No information in context will ever be sent to CommandBar (https://www.commandbar.com/docs/metadata/overview)
      window.CommandBar.addMetadata('activeFolder', () => {
        return this.$store.getters['assets/active']
      })
      window.CommandBar.addMetadata('isActiveSmartFolder', () => {
        return this.$store.getters['assets/isActiveSmartFolder']
      })
      window.CommandBar.addMetadata('project', () => {
        return this.$store.getters['project/project']
      })
    },

    shutdownCommandBar() {
      window.CommandBar.shutdown()
    },

    addCommandBarCallbacks() {
      // Logout callback
      window.CommandBar.addCallback('logout', () =>
        this.$router.push('/logout')
      )
      // New folder callback
      window.CommandBar.addCallback('newFolder', (args, context) =>
        this.$store
          .dispatch('assets/newFolder', {
            project: context.project,
            parent: context.activeFolder,
            name: args.folderName
          })
          .then((result) => {
            this.$notify({
              group: 'success',
              type: 'success',
              data: { text: 'Folder created' }
            })
          })
          .catch((response) =>
            this.$notify({
              group: 'error',
              type: 'error',
              data: { title: 'Failed to create folder' }
            })
          )
      )
      // window.CommandBar.addCallback('uploadContent', () =>
      // )

      // App dark mode callback
      window.CommandBar.addCallback('appDarkMode', (args) => {
        this.$store.dispatch('preferences/setAppDarkMode', {
          choice: args.preference.value
        })
      })

      // app language callback
      window.CommandBar.addCallback('appLanguage', (args) => {
        this.$store.dispatch('preferences/setAppLanguage', {
          choice: args.preference.value
        })
      })
      // change layout callback
      window.CommandBar.addCallback('changeLayout', (args) => {
        this.$store.dispatch('preferences/updateAssetsLayout', {
          layout: args.layouts.value
        })
        this.$bus.$emit('layout-change', args.layouts.value)
      })
    },
    addCommandBarCommands() {
      // sign out command
      window.CommandBar.addCommand({
        text: 'Sign Out',
        name: 'sign_out',
        category: 'User',
        arguments: {},
        template: {
          type: 'callback',
          value: 'logout',
          operation: 'router'
        }
      })

      // dark mode preference command
      window.CommandBar.addCommand({
        text: 'Dark mode preference',
        name: 'dark_mode_preference',
        category: 'Settings',
        arguments: {
          preference: {
            type: 'set',
            value: [
              {
                label: this.$t('components.modals.app-preferences.light-mode'),
                value: 'light',
                id: 1
              },
              {
                label: this.$t('components.modals.app-preferences.dark-mode'),
                value: 'dark',
                id: 2
              },
              {
                label: this.$t(
                  'components.modals.app-preferences.use-system-preference'
                ),
                value: 'system',
                id: 3
              }
            ],
            order_key: 0
          }
        },
        template: {
          type: 'callback',
          value: 'appDarkMode',
          operation: 'blank'
        }
      })

      // App language preference command
      // window.CommandBar.addCommand({
      //   text: 'App language preference',
      //   name: 'app_langauge_preference',
      //   category: 'Settings',
      //   arguments: {
      //     preference: {
      //       type: 'set',
      //       value: [
      //         { label: 'English', value: 'en', id: 1 },
      //         { label: 'Korean', value: 'ko', id: 3 },
      //         { label: 'French', value: 'fr', id: 4 },
      //         { label: 'Spanish', value: 'es', id: 5 },
      //         { label: 'German', value: 'de', id: 6 }
      //       ],
      //       order_key: 0
      //     }
      //   },
      //   template: {
      //     type: 'callback',
      //     value: 'appLanguage',
      //     operation: 'blank'
      //   }
      // })

      // layout change command
      window.CommandBar.addCommand({
        text: 'Change Layout',
        name: 'change_layout',
        category: 'Settings',
        arguments: {
          layouts: {
            type: 'set',
            value: [
              {
                label: 'List Layout',
                value: 'list',
                id: 1
              },
              {
                label: 'Grid Layout',
                value: 'card',
                id: 2
              }
            ],
            order_key: 0
          }
        },
        template: {
          type: 'callback',
          value: 'changeLayout',
          operation: 'blank'
        }
      })

      // new folder command
      window.CommandBar.addCommand({
        text: 'New Folder',
        name: 'new_folder',
        category: 'Create',
        arguments: {
          folderName: {
            type: 'provided',
            value: 'text',
            order_key: 0,
            label: 'Enter name for new folder'
          }
        },
        availability_rules: [
          {
            type: 'context',
            reason: 'Must be navigated in an active folder',
            operator: 'isDefined',
            field: 'activeFolder'
          },
          {
            type: 'context',
            operator: 'isFalse',
            field: 'isActiveSmartFolder',
            reason: 'Cannot create items inside smart folders'
          }
        ],
        template: {
          type: 'callback',
          value: 'newFolder',
          operation: 'blank'
        }
      })

      // upload content command
      // window.CommandBar.addCommand({
      //   text: 'Upload Content',
      //   name: 'upload_content',
      //   arguments: {},
      //   availability_rules: [
      //     {
      //       type: 'context',
      //       reason: 'Must be navigated in an active folder',
      //       operator: 'isDefined',
      //       field: 'activeFolder'
      //     },
      //     {
      //       type: 'context',
      //       operator: 'isFalse',
      //       field: 'isActiveSmartFolder',
      //       reason: 'Cannot create items inside smart folders'
      //     }
      //   ],
      //   template: {
      //     type: 'callback',
      //     value: 'uploadContent',
      //     operation: 'blank'
      //   }
      // })
    }
  }
}
