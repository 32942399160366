import store from '../store'

export default [
  {
    path: '/d/:slug',
    component: () =>
      import(/* webpackChunkName: "download" */ '@/views/Download.vue'),
    beforeEnter: async (to, from, next) => {
      store.dispatch('app/loadAppConfig')

      try {
        await store.dispatch('downloads/loadDownloadBySlug', {
          slug: to.params.slug
        })
      } catch (error) {
        // Skip the error, the component will handle it
      }

      next()
    },
    name: 'download-details',
    meta: {
      isAuthenticated: false
    }
  }
]
