import * as api from '@utils/api.js'
import { updateInListByKey } from '@utils/misc.js'

const state = {
  data: [],
  seen: [],

  loaded: false,
  loading: false,
  loadingError: false
}

const getters = {
  loading(state) {
    return state.loading
  },
  loaded(state) {
    return state.loaded
  },
  loadingError(state) {
    return state.loadingError
  },
  projectTags: (state) => (id) => {
    let found = state.data.filter((row) => row.project_id === id)
    if (found === undefined) return []
    return found
  },
  assetTags: (state) => (id) => {
    let found = state.data.filter((row) => row.asset_id === id)
    if (found === undefined) return []
    return found
  }
}

const mutations = {
  setLoading(state) {
    state.loading = true
    state.loadingError = false
  },
  setLoaded(state) {
    state.loading = false
    state.loadingError = false
    state.loaded = true
  },
  setLoadingError(state) {
    state.loading = false
    state.loadingError = true
  },
  addItem(state, item) {
    state.data = updateInListByKey(state.data, 'id', item.id, item)
    if (!state.seen.includes(item.id)) {
      state.seen.push(item.id)
    }
  },

  removeItem(state, item) {
    var index = state.data.findIndex((e) => e.id === item.id)
    if (index > -1) state.data.splice(index, 1)

    // Also remove from seen
    var i = state.seen.findIndex((id) => id === item.id)
    if (i > -1) state.seen.splice(i, 1)
  }
}

const actions = {
  addItem({ commit }, { tag }) {
    commit('addItem', tag)
  },
  removeItem({ commit }, { tag }) {
    commit('removeItem', tag)
  },
  createTag({ dispatch }, { payload }) {
    let uri = 'api/tags'

    return api
      .rawApiPostCall(uri, payload)
      .then((response) => {
        response.data.forEach((row) => {
          dispatch('addItem', { tag: row })
        })

        return response
      })
      .catch((err) => {
        throw err
      })
  },
  editTag({ dispatch }, { tagId, payload }) {
    let uri = 'api/tags/' + tagId

    return api
      .rawApiPatchCall(uri, payload)
      .then((response) => {
        dispatch('addItem', { tag: response.data.data })

        return response
      })
      .catch((err) => {
        throw err
      })
  },
  deleteTag({ dispatch }, { tag }) {
    let uri = 'api/tags/' + tag.id

    return api
      .rawApiDeleteCall(uri)
      .then((response) => {
        dispatch('removeItem', { tag: tag })

        return response
      })
      .catch((err) => {
        throw err
      })
  },

  loadProjectTags({ commit, dispatch }, { projectId, force }) {
    if (force === undefined) force = false

    // // Early return check
    // if (!force) {
    //   let find = state.items.find(row => (row.asset_id === projectId))
    //   if (find !== undefined) return find
    // }

    let uri = 'api/tags'
    let options = { params: { project_uuid: projectId } }
    commit('setLoading')

    return api
      .rawApiGetCall(uri, options)
      .then((response) => {
        dispatch('handleReturn', response.data)
      })
      .catch((err) => {
        commit('setLoadingError')
        throw err
      })
  },
  loadAssetTags({ commit, dispatch }, { assetId, force }) {
    if (force === undefined) force = false
    let uri = 'api/tags'
    let options = { params: { asset_uuid: assetId } }
    commit('setLoading')

    return api
      .rawApiGetCall(uri, options)
      .then((response) => {
        dispatch('handleReturn', response.data)
      })
      .catch((err) => {
        commit('setLoadingError')
        throw err
      })
  },

  handleReturn({ commit, dispatch }, data) {
    let dataCleaned = []
    data.data.forEach((row) => {
      dataCleaned.push(row.data)
    })

    dataCleaned.forEach((row) => {
      dispatch('addItem', { tag: row })
    })

    commit('setLoaded')
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
