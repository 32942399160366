import Vue from 'vue'
import vueSpaces from '@utils/vue-spaces'
import { ObserveVisibility } from 'vue-observe-visibility'
import PortalVue from 'portal-vue'
import FloatingVue from 'floating-vue'

import 'floating-vue/dist/style.css'
Vue.use(PortalVue)

window.Promise = require('promise')
window.Spark = {}

// Set our default alertify settings

Vue.use(vueSpaces)

let FloatingVueConfig = {
  placement: 'top',
  delay: 0,
  container: 'body',
  boundary: undefined,
  autoHide: true,
  disposeTimeout: 1000,
  themes: {
    tooltip: {
      triggers: ['hover', 'focus'],
      hideTriggers: (triggers) => [...triggers, 'click'],
      loadingContent: '...',
      $resetCss: true
    },
    dropdown: {
      placement: 'bottom',
      delay: 0,
      triggers: ['click'],
      container: 'body',
      boundary: undefined,
      autoHide: true,
      handleResize: true,
      $resetCss: true
    }
  }
}
Vue.use(FloatingVue, FloatingVueConfig)

Vue.directive('observe-visibility', ObserveVisibility)

require('./forms/bootstrap')

// Error handling for Sentry
// window.Sentry = require('@sentry/browser')
// window.SentryIntegrations = require('@sentry/integrations')

// window.Sentry.init({
//   dsn: process.env.VUE_APP_SENTRY_PUBLIC_DSN,
//   environment: process.env.VUE_APP_MODE,
//   integrations: [new SentryIntegrations.Vue({
//     Vue,
//     attachProps: true
//   })]
// })
