const state = {
  assetSearchFolders: false,
  assetUploadTray: false,
  assetVersioning: true
}
const getters = {
  getFlag: (state) => (flag) => {
    return state[flag]
  },
  assetSearchFolders(state) {
    return state.assetSearchFolders
  },
  assetUploadTray(state) {
    return state.assetUploadTray
  }
}

const mutations = {
  SET_FLAG(state, { key, value }) {
    state[key] = value
  },

  STORE_ASSET_SEARCH_FOLDER_CHOICE(state, choice) {
    state.appDarkMode = choice
  },
  STORE_ASSET_UPLOAD_TRAY_CHOICE(state, choice) {
    state.assetUploadTray = choice
  }
}

const actions = {
  setFlag({ commit }, { key, value }) {
    commit('SET_FLAG', { key: key, value: value })
  },
  setAssetSearchFolders({ commit }, value) {
    commit('STORE_ASSET_SEARCH_FOLDER_CHOICE', value)
  },
  setAssetUploadTray({ commit }, value) {
    commit('STORE_ASSET_UPLOAD_TRAY_CHOICE', value)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
