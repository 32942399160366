import * as api from '@utils/api.js'
import { updateInListByKey } from '@utils/misc.js'

const state = {
  data: [],
  seen: [],
  loaded: false,
  loading: false,
  loadingError: false
}

const getters = {
  loading(state) {
    return state.loading
  },
  loaded(state) {
    return state.loaded
  },
  loadingError(state) {
    return state.loadingError
  },

  thread: (state) => (id) => {
    let found = state.data.filter(
      (row) => row.resource_id === id && !row.is_reply
    )
    if (found === undefined) return []
    return found
  },
  threadReplies: (state) => (id) => {
    let found = state.data.filter((row) => row.parent_comment_id === id)
    if (found === undefined) return []
    return found
  },

  latestByProject: (state) => (id) => {
    let found = state.data.filter((row) => row.project_id === id)
    if (found === undefined) return []

    let sorted = found.sort(function (a, b) {
      let c = new Date(a.created_at)
      let d = new Date(b.created_at)
      return d - c
    })

    return sorted.slice(0, 8)
  }
}

const mutations = {
  setLoading(state) {
    state.loading = true
    state.loadingError = false
  },
  setLoaded(state) {
    state.loading = false
    state.loadingError = false
    state.loaded = true
  },
  setLoadingError(state) {
    state.loading = false
    state.loadingError = true
  },
  addItem(state, item) {
    state.data = updateInListByKey(state.data, 'id', item.id, item, true)
    if (!state.seen.includes(item.id)) {
      state.seen.push(item.id)
    }
  },

  removeItem(state, item) {
    var index = state.data.findIndex((e) => e.id === item.id)
    if (index > -1) state.data.splice(index, 1)

    // Also remove from seen
    var i = state.seen.findIndex((id) => id === item.id)
    if (i > -1) state.seen.splice(i, 1)
  },

  clearAssetsComments(sate, asset) {
    let foundState = state.data.filter(
      (comment) => comment.resource_id !== asset.id
    )
    if (foundState !== undefined) state.data = foundState

    let foundSeen = state.seen.filter(
      (comment) => comment.resource_id !== asset.id
    )
    if (foundSeen !== undefined) state.seen = foundSeen
  }
}

const actions = {
  addItem({ commit }, { comment }) {
    commit('addItem', comment)
  },

  removeItem({ commit }, { comment }) {
    commit('removeItem', comment)
  },

  delete({ dispatch }, { comment }) {
    dispatch('removeItem', { comment: comment })
    let uri = 'api/comments/' + comment.id

    return api.rawApiDeleteCall(uri).catch((error) => {
      console.log(error)
    })
  },

  editComment({ dispatch }, { resourceId, payload }) {
    let uri = 'api/comments/' + resourceId
    return api
      .rawApiPatchCall(uri, payload)
      .then((response) => {
        dispatch('addItem', { comment: response.data.data })
        return response
      })
      .catch((err) => {
        throw err
      })
  },

  addComment({ dispatch }, { resourceId, payload }) {
    let uri = 'api/comments?resource_id=' + resourceId

    return api
      .rawApiPostCall(uri, payload)
      .then((response) => {
        dispatch('addItem', { comment: response.data.data })

        return response
      })
      .catch((err) => {
        throw err
      })
  },

  loadRecentItems({ commit, dispatch }, { project }) {
    // Base Api call
    let uri = 'api/comments'
    let options = { params: { project_id: project.id } }

    commit('setLoading')
    return api
      .rawApiGetCall(uri, options)
      .then((response) => {
        dispatch('handleReturn', response.data)
      })
      .catch((err) => {
        commit('setLoadingError')
        throw err
      })
  },

  loadCommentsForAsset({ commit, dispatch }, { assetId, force }) {
    if (force === undefined) force = false

    let uri = 'api/comments?resource_id=' + assetId
    let options = { params: { resource_id: assetId } }
    commit('setLoading')

    return api
      .rawApiGetCall(uri, options)
      .then((response) => {
        dispatch('handleReturn', response.data)
      })
      .catch((err) => {
        commit('setLoadingError')
        throw err
      })
  },
  loadRepliesForComment({ commit, dispatch }, { commentId, force }) {
    if (force === undefined) force = false

    let uri = `api/comments/${commentId}/children`
    commit('setLoading')

    return api
      .rawApiGetCall(uri)
      .then((response) => {
        dispatch('handleReturn', response.data)
      })
      .catch((err) => {
        commit('setLoadingError')
        throw err
      })
  },

  loadSpecificComment({ commit, dispatch }, { commentId, force }) {
    if (force === undefined) force = false

    let uri = `api/comments/${commentId}`
    commit('setLoading')

    return api
      .rawApiGetCall(uri)
      .then((response) => {
        dispatch('addItem', { comment: response.data.data })
      })
      .catch((err) => {
        commit('setLoadingError')
        throw err
      })
  },
  handleReturn({ commit, dispatch }, data) {
    let dataCleaned = []
    data.data.forEach((row) => {
      dataCleaned.push(row.data)
    })

    let nextLink = null
    data.pagination.links.forEach((row) => {
      if (row.rel === 'pagination.next') {
        nextLink = row.url
      }
    })

    dataCleaned.forEach((row) => {
      dispatch('addItem', { comment: row })
    })

    if (nextLink) {
      dispatch('loadNext', { link: nextLink })
    } else {
      commit('setLoaded')
    }
  },
  clearAssetsComments({ commit }, { asset }) {
    commit('clearAssetsComments', asset)
  },
  loadNext({ dispatch }, { link }) {
    return api.rawApiGetCall(link).then((response) => {
      dispatch('handleReturn', response.data)
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
