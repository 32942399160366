import * as api from '@utils/api.js'
import {
  findInListByKey,
  replaceInListByKey,
  noopPromise
} from '@utils/misc.js'

const state = {
  members: [],
  guests: [],
  invites: [],
  invitations: null,
  team: null,
  teamLoaded: false,
  teamLoading: false,
  teamLoadingError: false
}

const actions = {

  setTeamName({ commit }, { team, name }) {
    commit('setTeamName', { team, name })
  },

  setTeamLoading({ commit }) {
    commit('setTeamLoading')
  },

  setTeamLoadingError({ commit }) {
    commit('setTeamLoadingError')
  },

  setTeamLoaded({ commit }) {
    commit('setTeamLoaded')
  },

  setTeamUnloaded({ commit }) {
    commit('setTeamUnloaded')
  },

  setActiveTeam({ commit }, { team }) {
    commit('setActiveTeam', { team })
    return noopPromise(team)
  },

  getTeam ({ commit }, { teamId }) {
    const uri = 'api/teams'
    const options = { params: { team_id: teamId } }

    return api.rawApiGetCall(uri, options)
      .then((response) => {
        let team = response.data.data[0].data
        // commit('addTeam', { team })

        return team
      })
      .catch((error) => {
        console.error(error)
        return null
      })
  },

  fetchTeamMembersSoft({ dispatch, commit }, { teamId }) {
    if (state.teamLoaded !== true) {
      dispatch('fetchTeamMembers', { teamId })
    }
  }

}

const getters = {
  teamLoading(state) {
    return state.teamLoading
  },

  teamLoadingError(state) {
    return state.teamLoadingError
  },

  teamLoaded(state) {
    return state.teamLoaded
  },

  teamOpenInviteRows: (state) => {
    return state.invites
  },

  activeTeam: (state) => {
    return state.team
  },

  isActiveTeam: (state) => {
    return (teamId) => {
      let activeTeam = state.team
      return activeTeam ? activeTeam.id === teamId : false
    }
  },

  // Team + User permissions
  // These could likely be moved elsewhere but they need
  // awareness of the active team or project
  currentRole: (state, getters) => {
    if (state.team == null) {
      return null
    }
    return state.team.team_role
  },

  isUserOwner: (state, getters) => {
    return getters.currentRole === 'owner'
  },
  isUserAdminOrOwner: (state, getters) => {
    return getters.currentRole === 'owner' || getters.currentRole === 'admin'
  },
  isUserMember: (state, getters) => {
    return (
      getters.currentRole === 'member_full' ||
      getters.currentRole === 'member_limited'
    )
  },
  isUserGuestOrVisitor: (state, getters) => {
    return getters.isUserGuest || !getters.isUserLoggedIn
  },
  isUserGuest: (state, getters) => {
    return getters.currentRole === 'guest_limited'
  },
  isUserLoggedIn: (state, getters) => {
    return getters.user !== null
  },

  // TEAM ACL
  canUserUpdateTeamBilling: (state, getters) => {
    return getters.isUserAdminOrOwner
  },
  canUserSeeTeamBilling: (state, getters) => {
    return getters.isUserAdminOrOwner
  },
  canUserSeeTeamUsage: (state, getters) => {
    return getters.isUserAdminOrOwner
  },
  canUserAddTeamProject: (state, getters) => {
    return getters.isUserAdminOrOwner
  },
  canUserSeeTeamDetails: (state, getters) => {
    return !getters.isUserGuestOrVisitor
  },
  canUserSeeTeamMemberOptions: (state, getters) => {
    return getters.isUserAdminOrOwner
  },
  canUserSeeTeamGuestOptions: (state, getters) => {
    return getters.isUserAdminOrOwner
  },
  canUserSeeTeamOpenInvites: (state, getters) => {
    return getters.isUserAdminOrOwner
  },
  canUserAddTeamInviteMembers: (state, getters) => {
    return getters.isUserAdminOrOwner
  },
  canUserEditTeamMemberAccess: (state, getters) => {
    return getters.isUserAdminOrOwner
  },
  canUserEditTeamGuestAccess: (state, getters) => {
    return getters.isUserAdminOrOwner
  },
  canUserSeeTeamGuests: (state, getters) => {
    return getters.isUserAdminOrOwner
  },
  canUserSeeTeam: (state, getters) => {
    return !getters.isUserGuestOrVisitor
  },
  canUserResendTeamInvitation: (state, getters) => {
    return getters.isUserAdminOrOwner
  },
  canUserRevokeTeamInvitation: (state, getters) => {
    return getters.isUserAdminOrOwner
  }
  // End TEAM ACL
}

const mutations = {

  addTeam (state, { team }) {

  },
  setTeamName(state, { team, name }) {
    state.team.name = name
  },

  setTeamUnloaded(state) {
    state.teamLoaded = false
    state.teamLoading = false
    state.teamLoadingError = false
  },

  setTeamLoaded(state) {
    state.teamLoaded = true
    state.teamLoading = false
    state.teamLoadingError = false
  },

  setTeamLoading(state) {
    state.teamLoaded = false
    state.teamLoading = true
    state.teamLoadingError = false
  },

  setTeamLoadingError(state) {
    state.teamLoaded = false
    state.teamLoading = false
    state.teamLoadingError = true
  },

  setActiveTeam(state, { team }) {
    state.team = team
  },

  fetchTeam({ dispatch, state, commit }, { teamId }) {
    // console.log('TEAM.js get Team')
  },

  setTeam(state, { team }) {
    state.team = team
  },

  setTeamMembers(state, { teamId, members }) {
    // There's some awkward race condition here
    // We've already got the updated data
    // so we'll just wipe the local cache out anyway to sidestep
    state.members = []

    if (findInListByKey(state.members, 'team_id', teamId) == null) {
      state.members.push({ team_id: teamId, members: members })
      return members
    } else {
      replaceInListByKey(state.members, 'team_id', teamId, { members: members })
    }
  },

  setTeamGuests(state, { teamId, guests }) {
    // There's some awkward race condition here
    // We've already got the updated data
    // so we'll just wipe the local cache out anyway to sidestep
    state.guests = []

    let arr = { team_id: teamId, guests: guests }
    if (findInListByKey(state.guests, 'team_id', teamId) == null) {
      state.guests.push(arr)
      return guests
    } else {
      // Already have some. We should refresh the cache state here.
      state.guests = replaceInListByKey(state.guests, 'team_id', teamId, arr)
    }
  },

  setTeamInvitations(state, { invitations }) {
    state.invitations = invitations
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
