export default {

  data () {
    return {
      authCheckedAndReady: false,
      authCheckedAndErrored: false,
      authCheckCount: 0,
      authCheckCountLimit: 1
    }
  },

  mounted () {
    const urlParams = new URLSearchParams(window.location.search)
    const retry = urlParams.get('retry')
    if (retry > 0) this.authCheckCount = retry
  },

  methods: {
    loadUser() {
      return this.$store.dispatch('fetchUser')
    },
    softLoadTeams() {
      this.$store.dispatch('teams/fetchTeamsSoft')
    },
    softLoadProjects() {
      this.$store.dispatch('projectsList/softLoad')
    },
    redirectToError () {
      let redirect = '/error'
      this.$router.push(redirect)
    },
    redirectToAuthError () {
      let redirect = '/auth-error'
      this.$router.push(redirect)
    },
    authCheckAndRedirect() {
      if (this.isAuthed === true) {
        if (this.$route.meta.isAuthenticated === false) {
          let redirect = '/'

          if (this.$route.query.intended) {
            redirect = this.$route.query.intended
            this.$router.push(redirect)
          }
        }
      } else {
        if (this.$route.meta.isAuthenticated !== false) {
          console.log('[base mixin] push to redirect')
        }
      }
    },

    firstAuthCheck () {
      return this.$store.dispatch('fetchAuthCheck')
    },

    getTokenAndStart () {
      if (this.$route.meta.isAuthenticated === false) return

      this.$store.dispatch('auth/getAuth0Token').then(() => {
        if (this.authCheckedAndReady) {
          this.loadUser().then(response => {
            if (!response) {
              this.redirectToError()
            } else {
              this.softLoadProjects()
              this.softLoadTeams()
              this.authCheckAndRedirect()
            }
          })
        } else {
          this.firstAuthCheck().then(response => {
            this.authCheckedAndReady = true
          }).catch(err => {
            this.authCheckedAndErrored = true

            const urlParams = new URLSearchParams(window.location.search)
            this.authCheckCount++

            if (this.authCheckCountLimit >= this.authCheckCount) {
              urlParams.set('retry', this.authCheckCount)
              window.location.search = urlParams
            } else {
              this.$store.dispatch('auth/setAuthError', err)
              this.redirectToAuthError()
            }
          })
        }
      })
    }
  },
  watch: {

    authCheckedAndReady () {
      this.getTokenAndStart()
    },

    isAuthed: {
      handler: function (val) {
        if (val === true) {
          this.getTokenAndStart()
        }
      },
      immediate: true
    }
  },

  computed: {
    isAuthed () {
      return this.$store.getters['auth/isAuthed']
    }
  }
}
