export default class AssetConflictAction {
  static UNSET = new AssetConflictAction('UNSET')
  static CREATE_NEW_ASSET = new AssetConflictAction('CREATE_NEW_ASSET')
  static CREATE_NEW_ASSET_WITH_SUFFIX = new AssetConflictAction(
    'CREATE_NEW_ASSET_WITH_SUFFIX'
  )
  static CREATE_NEW_VERSION = new AssetConflictAction('CREATE_NEW_VERSION')

  constructor(name) {
    this.name = name
  }
}
