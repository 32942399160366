export default {
  data() {
    return {
      isFullscreen: false
    }
  },
  methods: {
    attachFullscreenListener() {
      document.addEventListener(
        'fullscreenchange',
        this.onFullscreenChange,
        false
      )
      document.addEventListener(
        'webkitfullscreenchange',
        this.onFullscreenChange,
        false
      )
      document.addEventListener(
        'mozfullscreenchange',
        this.onFullscreenChange,
        false
      )
    },
    dettachFullscreenListener() {
      document.removeEventListener(
        'fullscreenchange',
        this.onFullscreenChange,
        false
      )
      document.removeEventListener(
        'webkitfullscreenchange',
        this.onFullscreenChange,
        false
      )
      document.removeEventListener(
        'mozfullscreenchange',
        this.onFullscreenChange,
        false
      )
    },
    onFullscreenChange() {
      if (
        (document.fullScreenElement !== undefined &&
          document.fullScreenElement === null) ||
        (document.msFullscreenElement !== undefined &&
          document.msFullscreenElement === null) ||
        (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
        (document.webkitIsFullScreen !== undefined &&
          !document.webkitIsFullScreen)
      ) {
        this.isFullscreen = false
      } else {
        this.isFullscreen = true
      }
    },

    requestToggleFullscreen(element) {
      // ## The below if statement seems to work better ## if ((document.fullScreenElement && document.fullScreenElement !== null) || (document.msfullscreenElement && document.msfullscreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (
        (document.fullScreenElement !== undefined &&
          document.fullScreenElement === null) ||
        (document.msFullscreenElement !== undefined &&
          document.msFullscreenElement === null) ||
        (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
        (document.webkitIsFullScreen !== undefined &&
          !document.webkitIsFullScreen)
      ) {
        if (element.requestFullScreen) {
          element.requestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen(element.ALLOW_KEYBOARD_INPUT)
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen()
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
    }
  }
}
