import fullscreen from '@components/utility/fullscreen-mixin.js'

export const appDarkMode = {
  mixins: [fullscreen],

  computed: {
    appDarkMode() {
      if (this.isFullscreen) return 'dark'
      if (this.$store.getters['app/contextDarkModeOverride'] !== null)
        return this.$store.getters['app/contextDarkModeOverride']
      return this.$store.getters['preferences/appDarkMode']
    },

    isAppDarkModeEnabled() {
      switch (this.appDarkMode) {
        case 'dark':
          return true
        case 'light':
          return false
        case 'system':
          return window.matchMedia('(prefers-color-scheme: dark)').matches
        default:
          return false
      }
    }
  },

  watch: {
    appDarkMode() {
      this.updateBaseClass()
    }
  },

  mounted() {
    this.attachFullscreenListener()
  },

  beforeDestroy() {
    this.dettachFullscreenListener()
  },

  methods: {
    updateBaseClass() {
      if (this.appDarkMode === 'dark') this.addDarkClass()
      else if (
        this.appDarkMode === 'system' &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      )
        this.addDarkClass()
      else this.removeDarkClass()
    },

    attachSystemChangeListener() {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (event) => {
          this.updateBaseClass()
        })
    },

    addDarkClass() {
      document.querySelector('html').classList.add('dark')
    },

    removeDarkClass() {
      document.querySelector('html').classList.remove('dark')
    }
  }
}
