import Vue from 'vue'
import * as api from '@utils/api.js'
import { updateInListByKey } from '@utils/misc.js'
import router from '@/router'

const state = {
  // Share objects
  shares: [],
  // Assets in assets_explorer_browser view
  explorerAssets: [],
  // Assets in the asset view
  viewAssets: [],

  rootFolder: {},

  // Loading states
  loading: false,
  loadingError: false,
  loaded: false,
  tokens: {},

  links: {},
  // active asset uuid
  active: null
}

const getters = {
  loading(state) {
    return state.loading
  },
  loaded(state) {
    return state.loaded
  },
  loadingError(state) {
    return state.loadingError
  },

  getShareBySlug: (state) => (slug) => {
    let found = state.shares.find((row) => row.slug === slug)
    if (found === undefined) return null
    return found
  },

  getAssetById: (state) => (id) => {
    let found = state.viewAssets.find((row) => row.id === id)
    if (found === undefined) return null
    return found
  },
  getFolderAssetById: (state) => (id) => {
    let found = state.explorerAssets.find((row) => row.id === id)
    if (found === undefined) return null
    return found
  },
  getFolderAssetsById: (state) => (id) => {
    let found = state.explorerAssets.filter((asset) => asset.parent_id === id)

    if (found === undefined) return []
    return found
  },
  getShareFolders(state) {
    let found = state.explorerAssets.filter((asset) => asset.type === 'folder')
    if (found === undefined) return []
    return found
  },
  shares(state) {
    return state.shares
  },
  getRootFolder(state) {
    return state.rootFolder
  },
  getSharedBySlug: (state) => (slug) => {
    let found = state.shares.find((row) => row.slug === slug)
    if (found === undefined) return null
    return found
  },
  getTokenForShare: (state) => (slug) => {
    if (!state) {
      return null
    }
    // Check if the tokens part of the state is initialized
    if (!state.tokens) {
      console.error('State tokens are not initialized yet.')
      return null
    }

    // Check if the specific token by slug is present
    const token = state.tokens[slug]
    if (!token) {
      console.warn(`Token for slug ${slug} not found.`)
      return null
    }

    return token
  },

  getLinksByAssetForShare: (state) => (id) => {
    let links = state.links[id]
    if (links !== undefined) return links
    return {}
  },

  getLinkForShareByType: (state) => (id, type) => {
    let links = state.links[id]
    if (links !== undefined) {
      let link = links[type]
      if (link !== undefined) return link
    }
    return null
  },
  getPaginationLinksForAsset: (state) => (id) => {
    const paginationTypes = [
      'pagination.first',
      'pagination.last',
      'pagination.next',
      'pagination.previous',
      'self'
    ]

    let links = state.links[id]
    if (links !== undefined) {
      return Object.fromEntries(
        Object.entries(links).filter(([key]) => paginationTypes.includes(key))
      )
    }
    return null
  }
}
const mutations = {
  setLoading(state) {
    state.loading = true
    state.loadingError = false
  },
  setLoaded(state) {
    state.loading = false
    state.loadingError = false
    state.loaded = true
  },
  setLoadingError(state) {
    state.loading = false
    state.loadingError = true
  },
  addShare(state, share) {
    state.shares = updateInListByKey(state.shares, 'slug', share.slug, share)
  },
  addToken(state, { shareSlug, token }) {
    if (!state.tokens[shareSlug]) {
      Vue.set(state.tokens, shareSlug, token)
    } else {
      state.tokens[shareSlug] = token
    }
  },
  addLinks(state, { links, id, overwrite = false }) {
    if (!state.links.hasOwnProperty(id)) {
      Vue.set(state.links, id, {})
    }

    links.forEach((link) => {
      if (overwrite || !state.links[id].hasOwnProperty(link.rel)) {
        Vue.set(state.links[id], link.rel, link.url)
      }
    })
  },
  setActive(state, assetId) {
    state.active = assetId
  },
  setRootFolder(state, rootFolder) {
    state.rootFolder = rootFolder
  },
  addAssetsToExplorer(state, { assets }) {
    let length = assets.length

    for (let index = 0; index < length; index++) {
      let value = assets[index].id
      state.explorerAssets = updateInListByKey(
        state.explorerAssets,
        'id',
        value,
        assets[index]
      )
    }
  },
  addAssetToView(state, { asset }) {
    state.viewAssets = updateInListByKey(
      state.viewAssets,
      'id',
      asset.id,
      asset
    )
  },
  resetState(state) {
    // Reset the state properties to their initial values
    state.shares = []
    state.explorerAssets = []
    state.viewAssets = []
    state.rootFolder = {}
    state.loading = false
    state.loadingError = false
    state.loaded = false
    state.links = {}
    state.active = null
  },
  deleteShareViewAsset(state, { assetId }) {
    const assetIndex = state.viewAssets.findIndex(
      (asset) => asset.id === assetId
    )
    if (assetIndex !== -1) {
      state.viewAssets.splice(assetIndex, 1)
    }
  },

  deleteShareExplorerAsset(state, { assetId }) {
    const assetIndex = state.explorerAssets.findIndex(
      (asset) => asset.id === assetId
    )
    if (assetIndex !== -1) {
      state.explorerAssets.splice(assetIndex, 1)
    }
  },
  deleteToken(state, { shareSlug }) {
    if (state.tokens[shareSlug]) {
      Vue.delete(state.tokens, shareSlug)
    }
  }
}

const actions = {
  setActive({ commit }, { assetId }) {
    commit('setActive', assetId)
  },
  loadShare({ commit, getters }, { slug, params = {} }) {
    const uri = 'api/shares?slug=' + slug
    let options = {
      // Example of params: params: { password: '', shareSlug: share.slug, asset: share.asset_id }
      params: params
    }
    const token = getters.getTokenForShare(slug)

    if (token) {
      options.headers = {
        'x-drive-share-token': token
      }
    }
    commit('setLoading')
    return api
      .rawApiGetCall(uri, options)
      .then((response) => {
        const token = response?.headers['x-drive-share-token'] || ''

        if (token) {
          commit('addToken', {
            shareSlug: response.data.data.slug,
            token: token
          })
        }

        const share = response.data.data
        const links = response.data.links
        commit('addShare', share)
        commit('addLinks', { links: links, id: share.slug, overwrite: false })
        commit('setLoaded')
        return share
      })
      .catch((error) => {
        commit('setLoadingError')
        throw error
      })
  },
  loadShareContentRoot({ commit, getters }, { shareSlug }) {
    commit('setLoading')
    const contentLink = getters.getLinkForShareByType(shareSlug, 'content')
    if (contentLink === null) return
    const options = {
      headers: {
        'x-drive-share-token': getters.getTokenForShare(shareSlug)
      }
    }
    return api
      .rawApiGetCall(contentLink, options)
      .then((response) => {
        // Might not be needed
        const root = response.data.data
        commit('setRootFolder', root)
        commit('addLinks', {
          links: response.data.links,
          id: root.id,
          overwrite: false
        })
        commit('setLoaded')
        return root
      })
      .catch((error) => {
        commit('setLoadingError')
        throw error
      })
  },
  loadShareContentAsset({ commit, dispatch, getters }, { assetId }) {
    const shareSlug = router.currentRoute.params.slug

    const contentShowLink = getters.getLinkForShareByType(
      assetId,
      'asset-shares-content.show'
    )
    commit('setLoading')
    const options = {
      headers: {
        'x-drive-share-token': getters.getTokenForShare(shareSlug)
      }
    }

    return api
      .rawApiGetCall(contentShowLink, options)
      .then((response) => {
        const asset = response.data.data
        dispatch('setActive', { assetId: assetId })
        commit('addAssetToView', { asset: asset })
        commit('setLoaded')
        return asset
      })
      .catch((error) => {
        commit('setLoadingError')
        throw error
      })
  },
  loadShareContentFolder({ commit, dispatch, getters }, { assetId }) {
    const shareSlug = router.currentRoute.params.slug

    const contentChildrenLink = getters.getLinkForShareByType(
      assetId,
      'asset-shares-content.children'
    )
    commit('setLoading')
    const options = {
      headers: {
        'x-drive-share-token': getters.getTokenForShare(shareSlug)
      }
    }

    return api
      .rawApiGetCall(contentChildrenLink, options)
      .then((response) => {
        dispatch('setActive', { assetId: assetId })
        // Removes the links from the response
        const cleanedAssets = []
        response.data.data.forEach((row) => {
          cleanedAssets.push(row.data)
          commit('addLinks', {
            links: row.links,
            id: row.data.id,
            overwrite: false
          })
        })

        commit('addAssetsToExplorer', { assets: cleanedAssets })

        commit('addLinks', {
          links: response.data.pagination.links,
          id: assetId,
          overwrite: false
        })

        commit('setLoaded')
        return cleanedAssets
      })
      .catch((error) => {
        commit('setLoadingError')
        throw error
      })
  },
  loadShareContentFolderPagination({ commit }, { assetId, url }) {
    commit('setLoading')
    const shareSlug = router.currentRoute.params.slug

    const options = {
      headers: {
        'x-drive-share-token': getters.getTokenForShare(shareSlug)
      }
    }
    return api
      .rawApiGetCall(url, options)
      .then((response) => {
        // Removes the links from the response
        const cleanedAssets = []
        response.data.data.forEach((row) => {
          cleanedAssets.push(row.data)
          commit('addLinks', {
            links: row.links,
            id: row.data.id
          })
        })

        commit('addAssetsToExplorer', { assets: cleanedAssets })
        commit('addLinks', {
          links: response.data.pagination.links,
          id: assetId,
          overwrite: true
        })
        commit('setLoaded')
        return cleanedAssets
      })
      .catch((error) => {
        commit('setLoadingError')
        throw error
      })
  },
  deleteShareAsset({ commit }, { assetId }) {
    commit('deleteShareExplorerAsset', { assetId: assetId })
    commit('deleteShareViewAsset', { assetId: assetId })
  },
  handleForbiddenError({ dispatch, commit, state }, { shareSlug }) {
    // 1. Remove the token for this shareSlug
    commit('deleteToken', { shareSlug })

    // 2. Reset the errored share
    dispatch('loadShare', { slug: shareSlug })

    // 3. Remove explorerAssets that match this shareSlug
    state.explorerAssets = state.explorerAssets.filter(
      (asset) => asset.in_share_slug !== shareSlug
    )

    // 4. Remove viewAssets that match this shareSlug
    state.viewAssets = state.viewAssets.filter(
      (asset) => asset.in_share_slug !== shareSlug
    )
    return true
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
