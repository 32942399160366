import * as api from '@utils/api.js'

const state = {
  // UI Preferences
  appDarkMode: 'system',
  appTimezone: 'Africa/Algiers',

  // Language Preferences
  appLanguage: 'en',

  // Video Playback Preferences
  playbackVolume: 100,
  playbackLoop: false,

  // Assets
  assetsLayout: null,
  assetsSortOrder: null,
  assetsTreePanelOpen: null,

  // Persistent UI Features
  mobileOverlayHidden: true,
  navSidebarHidden: false,

  // Project UI
  projectNavbarHidden: false,
  projectNavbarSupressingSidebar: false,

  // Viewer page
  viewerWideMode: true,

  // User Controllable flags
  itemEmbedsEnabled: false,
  projectAssetContextMenuEnabled: true,

  assetVideoPlayerUseVime: true,
  assetSharingEnabled: true,
  infoPanelActionTabEnabled: false,
  exposeFeatureFlagsUI: false,
  pullToRefreshEnabled: false,
  assetPreviewEnabled: false
  // End flags
}

const getters = {
  getFlag: (state) => (flag) => {
    return state[flag]
  },

  appDarkMode: (state) => {
    return state.appDarkMode
  },

  appTimezone: (state) => {
    return state.appTimezone
  },

  appLanguage: (state) => {
    return state.appLanguage
  },

  playbackVolume: (state) => {
    return state.playbackVolume
  },
  playbackLoop: (state) => {
    return state.playbackLoop
  },

  assetsLayout(state) {
    return state.assetsLayout
  },

  assetsSortOrder(state) {
    return state.assetsSortOrder
  },

  assetsTreePanelOpen(state) {
    return state.assetsTreePanelOpen
  },

  viewerWideMode(state) {
    return state.viewerWideMode
  },

  navSidebarHidden(state) {
    return state.navSidebarHidden
  },

  mobileOverlayHidden(state) {
    return state.mobileOverlayHidden
  },

  projectNavbarHidden(state) {
    return state.projectNavbarHidden
  }
}

const mutations = {
  SET_FLAG(state, { key, value }) {
    state[key] = value
  },

  STORE_DARK_MODE_CHOICE(state, choice) {
    state.appDarkMode = choice
  },

  STORE_TIMEZONE_CHOICE(state, choice) {
    state.appTimezone = choice
  },

  STORE_LANGUAGE_CHOICE(state, choice) {
    state.appLanguage = choice
  },

  setPlaybackVolume(state, value) {
    state.playbackVolume = value
  },
  setPlaybackLoop(state, value) {
    state.playbackLoop = value
  },

  setAssetsLayout(state, layout) {
    state.assetsLayout = layout
  },

  setAssetsSortOrder(state, order) {
    state.assetsSortOrder = order
  },

  setAssetsTreePanelOpen(state, open) {
    state.assetsTreePanelOpen = open
  },

  setNavSidebarHidden(state, value) {
    state.navSidebarHidden = Boolean(value)
  },

  toggleSidebarHidden(state) {
    state.navSidebarHidden = !state.navSidebarHidden
  },

  setViewerWideMode(state, value) {
    state.viewerWideMode = Boolean(value)
  },

  setMobileOverlayHidden(state, value) {
    state.mobileOverlayHidden = Boolean(value)
  },

  toggleMobileOverlayHidden(state) {
    state.mobileOverlayHidden = !state.mobileOverlayHidden
  },

  setProjectNavbarHidden(state, value) {
    value = Boolean(value)
    state.projectNavbarHidden = value

    if (value === true) {
      if (
        state.navSidebarHidden === false &&
        state.navSidebarHidden === false
      ) {
        // Force the sidebar hidden for now too
        state.projectNavbarSupressingSidebar = true
        state.navSidebarHidden = true
      }
    } else {
      if (state.projectNavbarSupressingSidebar === true) {
        state.projectNavbarSupressingSidebar = false
        state.navSidebarHidden = false
      }
    }
  }
}

const actions = {
  setFlag({ commit }, { key, value }) {
    commit('SET_FLAG', { key: key, value: value })
  },

  setAppDarkMode({ commit, rootGetters }, { choice }) {
    const user = rootGetters.user

    return api
      .updateUser(user.id, {
        preference_lightmode: choice
      })
      .then((response) => {
        commit('STORE_DARK_MODE_CHOICE', response.data.preference_lightmode)
      })
      .catch((err) => {
        console.error(err)
      })
  },

  setTimezone({ commit, rootGetters }, { choice }) {
    const user = rootGetters.user

    return api
      .updateUser(user.id, {
        preference_timezone: choice
      })
      .then((response) => {
        commit('STORE_TIMEZONE_CHOICE', response.data.preference_timezone)
      })
      .catch((err) => {
        console.error(err)
      })
  },

  setAppLanguage({ commit, rootGetters }, { choice }) {
    const user = rootGetters.user

    return api
      .updateUser(user.id, {
        preference_language: choice
      })
      .then((response) => {
        commit('STORE_LANGUAGE_CHOICE', response.data.preference_language)
      })
      .catch((err) => {
        console.error(err)
      })
  },

  setPlaybackVolume({ commit }, value) {
    commit('setPlaybackVolume', value)
  },
  setPlaybackLoop({ commit }, value) {
    commit('setPlaybackLoop', value)
  },

  updateAssetsLayout({ commit }, { layout }) {
    commit('setAssetsLayout', layout)
  },

  updateAssetsSortOrder({ commit }, { order }) {
    commit('setAssetsSortOrder', order)
  },

  updateAssetsTreePanelOpen({ commit }, { open }) {
    commit('setAssetsTreePanelOpen', open)
  },

  toggleSidebarHidden({ commit }) {
    commit('toggleSidebarHidden')
  },

  setNavSidebarHidden({ commit }, value) {
    commit('setNavSidebarHidden', value)
  },

  setMobileOverlayHidden({ commit }, value) {
    commit('setMobileOverlayHidden', value)
  },

  toggleMobileOverlayHidden({ commit }) {
    commit('toggleMobileOverlayHidden')
  },

  setViewerWideMode({ commit }, value) {
    commit('setViewerWideMode', value)
  },

  setProjectNavbarHidden({ commit }, value) {
    commit('setProjectNavbarHidden', value)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
