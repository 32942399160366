import * as api from '@utils/api.js'

const state = {
  projects: []
}

const getters = {}

const actions = {
  createProject({ dispatch }, { title, template, includeAssets }) {
    return api
      .createProject(title, template, includeAssets)
      .then((response) => {
        let projectData = response.data
        return projectData
      })
      .then((project) => {
        // XXX In the future this project should be added to the projects list
        // without a fetch
        dispatch('projectsList/reload')
        return project
      })
  },
  updateProjectTitle({ commit }, { project, title }) {
    commit('setProjectTitle', { project, title })
    return api.updateProjectTitle(project, title)
  },

  updateProjectIcon({ commit }, { project, icon }) {
    commit('setProjectIcon', { project, icon })
    return api.updateProjectIcon(project, icon)
  },

  updateProjectColor({ commit }, { project, color }) {
    commit('setProjectColor', { project, color })
    return api.updateProjectColor(project, color)
  }
}

const mutations = {
  setProjectTemplates(state, { templates }) {
    state.projectTemplates = templates
  },
  addProject(state, { project }) {
    state.projects.push(project)
  },
  setProjectTitle(state, { project, title }) {
    project.title = title
  },
  setProjectIcon(state, { project, icon }) {
    project.cover_icon = icon
  },
  setProjectColor(state, { project, color }) {
    project.cover_color = color
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
