import * as api from '@utils/api.js'

const state = {
  // State for all file types
  fileTypes: null,
  fileTypesAreSet: false,
  fileTypeErrorMessage: null
}

const actions = {
  fetchSystemSupportedFileTypes({ commit, dispatch }) {
    let supportedFiles = this.state.fileTypes.fileTypes

    if (supportedFiles === undefined || supportedFiles === null) {
      return api
        .fetchFileTypes()
        .then((fileTypes) => {
          commit('setFileType', { fileTypes })
        })
        .catch((reject) => {
          let message = reject.message
          commit('unsetFileType', { message })
        })
    }
  }
}

const mutations = {
  setFileType(state, { fileTypes }) {
    state.fileTypes = fileTypes
    state.fileTypesAreSet = true
    state.fileTypeErrorMessage = null
  },

  unsetFileType(state, { message }) {
    state.fileTypes = null
    state.fileTypesAreSet = false
    state.fileTypeErrorMessage = message
  }
}

const getters = {
  getFileTypes: (state) => {
    return state.fileTypes
  },

  fileTypeAreSet: (state) => {
    return state.fileTypesAreSet
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
