export const appLanguage = {
    computed: {
      appLanguage() {
        return this.$store.getters['preferences/appLanguage']
      }
    },

    mounted() {
      this.updateAppLanguage()
    },

    watch: {
        appLanguage() {
        this.updateAppLanguage()
      }
    },

    methods: {
        updateAppLanguage() {
            this.$i18n.locale = this.appLanguage
      }
    }
  }
