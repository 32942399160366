import * as api from '@utils/api.js'
import Vue from 'vue'

import DownloadStatus from '@src/enums/downloads/downloadStatus'

const state = {
  downloads: {},
  downloadZips: {}
}

const getters = {
  getDownloads(state) {
    return state.downloads
  },
  getDownload: (state) => (id) => {
    return state.downloads[id]
  },
  getCompletedDownloads(state) {
    return Object.values(state.downloads).reduce((downloads, download) => {
      if (download.status === DownloadStatus.COMPLETE.name) {
        downloads[download.id] = download
      }
      return downloads
    }, {})
  },
  getDownloadBySlug: (state) => (slug) => {
    for (let download of Object.values(state.downloads)) {
      if (download.slug === slug) {
        return download
      }
    }
  },
  getDownloadZipsById: (state) => (id) => {
    return state.downloadZips[id]
  }
}

const actions = {
  loadDownloadBySlug({ dispatch }, { slug }) {
    const uri = 'api/downloads/' + slug

    return api.rawApiGetCall(uri).then((response) => {
      const download = response.data.data

      return dispatch('addDownload', { download: download })
    })
  },

  addDownload({ commit }, { download }) {
    const zipKeys = download.zip_keys
    delete download.zip_keys

    commit('addDownload', download)
    commit('addDownloadZips', { id: download.id, zips: zipKeys })
    return download
  },

  updateDownloadStatus({ commit }, { slug, status }) {
    const uri = 'api/downloads/' + slug

    return api.rawApiPatchCall(uri, { status: status }).then((response) => {
      const download = response.data.data

      commit('addDownload', download)
      return download
    })
  }
}

const mutations = {
  addDownload(state, download) {
    Vue.set(state.downloads, download.id, download)
  },
  addDownloadZips(state, { id, zips }) {
    Vue.set(state.downloadZips, id, zips)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
