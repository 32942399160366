import Vue from 'vue'
import SpacesApp from './App.vue'
import router from './router'
import store from './store/index'
import NProgress from 'vue-nprogress'
import coreMixin from '@mixins/previz'
import echoInit from './echo.js'
import mixpanelInit from './mixpanel.js'
import i18n from './i18n'
import { keyboardShortcuts } from '@mixins/keyboard-shortcuts'
import { Auth0Plugin } from './auth'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import Notifications from 'vue-notification'
import { init } from 'commandbar'

import marked from 'marked'

import './main.css'

require('./components/bootstrap')
require('./bootstrap/index')

Vue.config.productionTip = false

// Init Echo
echoInit()
// Init mixpanel
mixpanelInit()

// Set the user context after the store has been initialized
// window.Sentry.setUser(store.getters.user)

// Create the global event bus.
const bus = new Vue({})
Object.defineProperty(Vue.prototype, '$bus', {
  get() {
    return this.$root.bus
  }
})

Vue.use(NProgress)
const nprogress = new NProgress({
  latencyThreshold: 250,
  showSpinner: false,
  http: false
})

Vue.use({
  install() {
    Vue.marked = marked
    Vue.prototype.$marked = marked
  }
})

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENTID,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

Sentry.init({
  Vue,
  dsn:
    process.env.VUE_APP_SENTRY_ENABLED === 'true'
      ? process.env.VUE_APP_SENTRY_DSN
      : null,
  integrations: [
    process.env.VUE_APP_SENTRY_ENABLED === 'true'
      ? new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['localhost', 'drive.disguise.cloud', /^\//]
        })
      : null
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.VUE_APP_SENTRY_ENABLED === 'true' ? 1.0 : 0.0
})

Vue.use(Notifications)

// 9bc12b77
init(process.env.VUE_APP_COMMANDBAR_ORGID)

// Create the main Vue application.
// eslint-disable-next-line no-new
new Vue({
  el: '#dc-spaces-app-root',
  nprogress,
  router,
  store,
  i18n,
  mixins: [coreMixin],
  data: {
    bus: bus
  },
  mounted() {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      // and stash the event so it can be triggered later.
      e.preventDefault()
      this.$store.dispatch('app/handlePWABeforeInstallPrompt', { prompt: e })
    })
  },
  render: function (createElement) {
    return createElement(SpacesApp)
  }
})

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // Here is where we check that click was outside the el and its children
      // ! NOTE: This will not work for dynamically recursive children
      if (!(el === event.target || el.contains(event.target))) {
        // And if it did, call method provided in attribute value
        vnode.context[binding.expression](event)

        // ! Replace the above line with this for Vue 3
        // https://stackoverflow.com/questions/63869859/migrating-detect-click-outside-custom-directive-from-vue-2-to-vue-3
        // binding.value(event, el)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})

Vue.mixin(keyboardShortcuts)
