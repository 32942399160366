import mixpanel from 'mixpanel-browser'

const mixpanelInit = () => {
  mixpanel.init(
    process.env.VUE_APP_MIXPANEL_TOKEN,
    {
      debug: process.env.VUE_APP_MODE === 'local',
      api_host: process.env.VUE_APP_MIXPANEL_HOST,
      batch_flush_interval_ms: 1000
    },
    'disguise'
  )
}

export default mixpanelInit
