import * as api from '@utils/api.js'

const actions = {
  submitFeedback({ dispatch }, { payload }) {
    let uri = 'api/feedback'

    return api
      .rawApiPostCall(uri, payload)
      .then((response) => {
        this.$notify({
          group: 'success',
          type: 'success',
          data: {
            text: 'Feedback received'
          }
        })

        return response
      })
      .catch((err) => {
        console.warn('ERROR ', err)
        return false
      })
  }
}

export default {
  namespaced: true,
  actions
}
