import axios from 'axios'
import store from '@/store/index'

// Create an Axios instance
const http = axios.create({
  baseURL: process.env.VUE_APP_SERVER_API_BASE
})

http.interceptors.request.use(
  async (config) => {
    const token = await store.dispatch('auth/getAuth0Token')

    if (store.getters['auth/isAuthed']) {
      config.headers['Authorization'] =
        store.getters['auth/token_type'] + ' ' + token
      config.headers['Accept'] = 'application/json'
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  (response) => {
    // Wrap the response data in an additional 'data' property

    return {
      data: response.data,
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
      config: response.config
    }
  },
  (error) => {
    // Handle errors
    return Promise.reject(error)
  }
)

// Base request function
export function request(
  method,
  urlTemplate,
  urlElements,
  payload,
  options = {}
) {
  let url = urlTemplate
  for (const key in urlElements) {
    url = url.replace(`{${key}}`, urlElements[key])
  }

  const config = {
    method: method,
    url: url,
    data: payload,
    ...options // Spread other properties from options
  }

  return http(config)
    .then((response) => response.data)
    .catch((error) =>
      Promise.reject(error.response ? error.response.data : error)
    )
}

// Base raw API calls
export function rawApiGetCall(uri, options = {}) {
  return http
    .get(uri, { ...options })
    .then((response) => {
      return response
    })
    .catch((error) =>
      Promise.reject(error.response ? error.response.data : error)
    )
}

export function rawApiPostCall(uri, payload) {
  return http
    .post(uri, payload)
    .then((response) => response)
    .catch((error) =>
      Promise.reject(error.response ? error.response.data : error)
    )
}

export function rawApiDeleteCall(uri, options = {}) {
  return http
    .delete(uri, { ...options })
    .then((response) => response)
    .catch((error) =>
      Promise.reject(error.response ? error.response.data : error)
    )
}

export function rawApiPatchCall(uri, payload) {
  return http
    .patch(uri, payload)
    .then((response) => response)
    .catch((error) =>
      Promise.reject(error.response ? error.response.data : error)
    )
}

// Login function
export function login(email, password) {
  return request('post', '/login', {}, { email, password })
}

// Team requests start
export function fetchTeams() {
  return request('get', 'api/teams?include=members')
}

export function createTeam(name) {
  return request('post', 'api/teams', {}, { name })
}
// Team requests end

// Project cover requests start
export function updateProjectCover(data) {
  return request(
    'post',
    'api/projects/{id}/cover',
    {
      id: data.project_id
    },
    data
  )
}

export function removeProjectCover(id) {
  return request('delete', 'api/projects/{id}/cover', {
    id: id
  })
}
// Project cover requests end

// User requests start
export function updateUser(userId, data) {
  return request(
    'patch',
    'api/users/{id}',
    {
      id: userId
    },
    data
  )
}
// User requests end

// Shares requests start
export function fetchSharesSettings(id) {
  return request('get', 'api/share-settings?asset_id={id}', { id: id })
}

export function updateShareSettings(settingId, data) {
  return request(
    'patch',
    'api/share-settings/{share_setting_id}',
    {
      share_setting_id: settingId
    },
    data
  )
}

export function createShareSetting(data) {
  return request('post', 'api/share-settings', {}, data)
}

export function deleteShareSetting(id) {
  return request('delete', 'api/share-settings/{id}', {
    id: id
  })
}
// Shares requests end

// Project requests start
export function createProject(title, teamId, template, includeAssets) {
  return request(
    'post',
    'api/projects',
    {},
    {
      title: title,
      team_id: teamId,
      template: template,
      include_assets: includeAssets
    }
  )
}

export function archiveProject(projectId) {
  return request('post', 'api/projects/{project_id}/archive', {
    project_id: projectId
  })
}

export function unarchiveProject(projectId) {
  return request('post', 'api/projects/{project_id}/restore', {
    project_id: projectId
  })
}

export function updateProjectTitle(project, title) {
  return request(
    'patch',
    'api/projects/{project_id}',
    { project_id: project.id },
    { title: title }
  )
}

export function updateProjectIcon(project, icon) {
  return request(
    'patch',
    'api/projects/{project_id}',
    { project_id: project.id },
    { cover_icon: icon }
  )
}

export function updateProjectColor(project, color) {
  return request(
    'patch',
    'api/projects/{project_id}',
    { project_id: project.id },
    { cover_color: color }
  )
}
// Project requests end

// Asset requests start
export function updateVersionCoverImage(data) {
  return request(
    'post',
    'api/asset-versions/{version_id}/cover',
    { version_id: data.version_id },
    data
  )
}

export function saveAssetVersionNewData(asset, data) {
  const payload = {
    data: data
  }

  return request(
    'post',
    'api/assets/{asset_id}/versions',
    { asset_id: asset.id },
    payload
  )
}

export function uploadAssetVersion(file, asset, extra) {
  const payload = new window.FormData()
  payload.append('file', file)
  return request(
    'post',
    'api/assets/{asset_id}/versions',
    { asset_id: asset.id },
    payload
  )
}

export function updateAssetVersion(asset, version, data) {
  return request(
    'patch',
    'api/assets/{asset_id}/versions/{version_id}',
    { asset_id: asset.id, version_id: version.id },
    data
  )
}

export function updateAssetActiveVersion(asset_id, payload) {
  return request(
    'patch',
    'api/assets/{asset_id}/version',
    {
      asset_id: asset_id
    },
    payload
  )
}

export function deleteAssetVersion(asset, version) {
  return request('delete', 'api/assets/{asset_id}/versions/{version_id}', {
    asset_id: asset.id,
    version_id: version.id
  })
}

export function newAssetFromDirectUpload(data) {
  return request('post', 'api/assets', {}, data)
}

export function updateAssetResource(data) {
  return request(
    'post',
    'api/assets/{asset_id}/resources',
    { asset_id: data.asset_id },
    data
  )
}

export function updateAssetResourceAssociation(data) {
  return request(
    'patch',
    'api/assets/{asset_id}/resources',
    { asset_id: data.asset_id },
    data
  )
}

export function newFolder(project, parent, name) {
  return request(
    'post',
    'api/assets-browser',
    {},
    {
      project_id: project.id,
      parent_id: parent,
      name: name,
      type: 'folder'
    }
  )
}

export function newPreviz(project, parent, name) {
  return request(
    'post',
    'api/assets-browser',
    {},
    {
      project_id: project.id,
      parent_id: parent,
      name: name,
      type: 'previz'
    }
  )
}

export function newComposition(project, parent, name) {
  return request(
    'post',
    'api/assets-browser',
    {},
    {
      project_id: project.id,
      parent_id: parent,
      name: name,
      type: 'composition'
    }
  )
}

export function saveSequence(assetId, data) {
  return request(
    'post',
    'api/assets/{id}/sequence',
    {
      id: assetId
    },
    {
      data: data
    }
  )
}

export function newSequenceItem(sceneId, name) {
  return request(
    'post',
    'api/assets-browser/{id}/sequences',
    {
      id: sceneId
    },
    {
      name: name
    }
  )
}

export function newAssetItem(project, parent, name, type) {
  return request(
    'post',
    'api/assets',
    {},
    {
      project_id: project.id,
      parent_id: parent,
      name: name,
      type: type
    }
  )
}

export function moveAssets(assets, folderId) {
  const ids = []
  assets.forEach((asset) => {
    ids.push(asset.id)
  })

  return request(
    'patch',
    'api/assets-browser/{asset_id}',
    {
      asset_id: ids[0]
    },
    {
      asset_ids: ids.join(','),
      folder_id: folderId
    }
  )
}

export function renameAsset(asset, name) {
  return request(
    'patch',
    'api/assets-browser/{asset_id}',
    {
      asset_id: asset.id
    },
    {
      name: name
    }
  )
}

export function lockAsset(asset, lock) {
  return request(
    'patch',
    'api/assets-browser/{asset_id}',
    {
      asset_id: asset.id
    },
    {
      locked: lock
    }
  )
}

export function duplicateAsset(asset, destination) {
  return request(
    'post',
    'api/assets/{asset_id}/duplicate',
    {
      asset_id: asset.id
    },
    {
      destination: destination
    }
  )
}

export function updateAsset(asset_id, payload) {
  return request(
    'patch',
    'api/assets/{asset_id}',
    {
      asset_id: asset_id
    },
    payload
  )
}

export function updateAssetSettings(asset_id, settings) {
  return request(
    'post',
    'api/assets/{asset_id}/settings',
    {
      asset_id: asset_id
    },
    {
      settings: settings
    }
  )
}

export function deleteAssets(assets) {
  const ids = []
  assets.forEach((asset) => {
    ids.push(asset.id)
  })

  return request('delete', 'api/assets-browser/{asset_id}', {
    asset_id: ids.join(',')
  })
}
// Asset requests end

// File type requests start
export function fetchFileTypes() {
  return request('get', 'api/file-type', {})
}
// File type requests end
