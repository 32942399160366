import * as api from '@utils/api.js'
import { updateInListByKey } from '@utils/misc.js'
import orderBy from 'lodash/orderBy'

const state = {
  items: [],
  seen: [],

  loading: false,
  loadingError: false,
  loaded: false
}

const getters = {
  loading(state) {
    return state.loading
  },
  loaded(state) {
    return state.loaded
  },
  loadingError(state) {
    return state.loadingError
  },
  getSharesForProject: (state) => (id) => {
    let subset = state.items.filter((row) => row.project_id === id)
    return orderBy(subset, ['created_at'], ['desc'])
  },
  getShareByAssetId: (state) => (id) => {
    let found = state.items.find((row) => row.asset_id === id)
    if (found === undefined) return null
    return found
  },
  getShareBySlug: (state) => (slug) => {
    let found = state.items.find((row) => row.slug === slug)
    if (found === undefined) return null
    return found
  },
  getAssetBySlug: (state) => (slug) => {
    return null
  }
}

const actions = {
  deleteShare({ commit }, { id }) {
    return api
      .deleteShareSetting(id)
      .then((response) => {
        commit('deleteShare', id)
        return response
      })
      .catch(() => {
        return false
      })
  },

  createShare({ commit }, { payload }) {
    return api
      .createShareSetting(payload)
      .then((response) => {
        let data = response.data
        commit('addShare', data)
        return data
      })
      .catch(() => {
        return false
      })
  },

  updateShareSetting({ commit }, { id, payload }) {
    return api
      .updateShareSettings(id, payload)
      .then((response) => {
        let data = response.data
        commit('addShare', data)
        return data
      })
      .catch((error) => {
        throw error
      })
  },

  loadSharesForProject({ commit }, { id, force }) {
    if (force === undefined) force = false
    // Early return check
    // if (!force) {
    //   if (state.seen.includes(id)) {
    //     return state.items.find(row => row.id === id)
    //   }
    // }

    let uri = 'api/share-settings?project_id=' + id
    commit('setLoading')

    return api
      .rawApiGetCall(uri)
      .then((response) => {
        let data = response.data.data
        data.forEach((row) => {
          commit('addShare', row.data)
        })
        commit('setLoaded')
        return true
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  loadShareForAsset({ commit, state }, { assetId, force }) {
    if (force === undefined) force = false
    // Early return check
    if (!force) {
      let find = state.items.find((row) => row.asset_id === assetId)
      if (find !== undefined) return find
    }

    let uri = 'api/share-settings?asset_id=' + assetId
    commit('setLoading')

    return api
      .rawApiGetCall(uri)
      .then((response) => {
        let data = response.data.data
        data.forEach((row) => {
          commit('addShare', row.data)
        })
        commit('setLoaded')
        return data
      })
      .catch(() => {
        commit('setLoadingError')
      })
  }
}

const mutations = {
  setLoading(state) {
    state.loading = true
    state.loadingError = false
  },
  setLoaded(state) {
    state.loading = false
    state.loadingError = false
    state.loaded = true
  },
  setLoadingError(state) {
    state.loading = false
    state.loadingError = true
  },
  addShare(state, item) {
    state.items = updateInListByKey(state.items, 'id', item.id, item, true)
    if (!state.seen.includes(item.id)) {
      state.seen.push(item.id)
    }
  },
  deleteShare(state, id) {
    var index = state.items.findIndex((e) => e.id === id)
    if (index > -1) state.items.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
