import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import intervalToDuration from 'date-fns/intervalToDuration'
import formatDuration from 'date-fns/formatDuration'
import { secondsToHours, addSeconds } from 'date-fns'

const numeral = require('numeral')

export default function (Vue, options) {
  Vue.directive('img-fallback', {
    bind: function (el, binding) {
      const { value } = binding
      const defaultLoading = '/img/default/loading/Rolling-1s-64px.gif'
      const defaultError = 'https://pbs.twimg.com/media/BXhh-sfIAAArh4S.jpg'
      const img = new Image()

      let loading = defaultLoading
      let error = defaultError
      let original = el.src

      let availableLoading = {
        largedark: '/img/default/loading/Rolling-1s-256px-dark.gif',
        smalldark: '/img/default/loading/Rolling-1s-64px-dark.gif',
        large: '/img/default/loading/Rolling-1s-256px.gif',
        small: '/img/default/loading/Rolling-1s-64px.gif'
      }

      let availableError = {
        largedark: '/img/default/loading/Rolling-1s-256px-dark.gif',
        smalldark: '/img/default/loading/Rolling-1s-64px-dark.gif',
        large: '/img/default/loading/Rolling-1s-256px.gif',
        small: '/img/default/loading/Rolling-1s-64px.gif'
      }

      if (typeof value === 'string') {
        if (availableLoading.hasOwnProperty(value)) {
          loading = availableLoading[value]
        }

        if (availableError.hasOwnProperty(value)) {
          error = availableError[value]
        }
      }

      img.src = original

      el.src = loading

      img.onload = () => {
        el.src = original
      }

      img.onerror = () => {
        el.src = error
      }
    }
  })

  Vue.filter('date', (value) => {
    return format(parseISO(value), 'MMMM do, yyyy')
  })

  Vue.filter('date_simple', (value) => {
    return format(parseISO(value), 'dd/MM/yyy')
  })

  Vue.filter('datetime', (value) => {
    return format(parseISO(value), 'MMMM do, yyyy h:mm a')
  })

  Vue.filter('relative', (value) => {
    return formatDistanceToNow(parseISO(value))
  })

  Vue.filter('relative_short', (value) => {
    let date = formatDistanceToNow(parseISO(value))

    const mapObj = {
      minute: 'm',
      minutes: 'm',
      about: '~',
      hour: 'hr',
      hours: 'hrs',
      day: 'd',
      days: 'ds',
      month: 'mo',
      months: 'mos',
      year: 'yr',
      years: 'yrs',
      over: '>',
      almost: '≈'
    }
    // replacing the sentence
    let shortDate = date.replace(/(less than a minute)/g, '<1m')
    // replacing remaining formatting words from date-fns' formatDistanceToNow function
    shortDate = shortDate.replace(
      /\b(?:minute|minutes|about|hour|hours|day|days|month|months|year|years|over|almost)\b/gi,
      (matched) => mapObj[matched]
    )
    // removing spaces
    shortDate = shortDate.replace(/\s/g, '')
    return shortDate
  })

  Vue.filter('firstchar', (value) => {
    let val = String(value)
    return val.substring(0, 1)
  })

  Vue.filter('duration', (value) => {
    value = parseInt(value)
    const duration = intervalToDuration({ start: 0, end: value * 1000 })
    return formatDuration(duration)
  })

  Vue.filter('content', (value) => {
    const hours = secondsToHours(value)
    value = addSeconds(new Date(0), value)
    if (hours >= 1) {
      return format(value, 'hh:mm:ss')
    } else {
      return format(value, 'mm:ss')
    }
  })

  Vue.filter('filesize', (value) => {
    // 03/07/2017 @charlesfleche
    // Number of digits applies on Bytes as well,
    // which makes no sense
    // See https://github.com/adamwdraper/Numeral-js/issues/460
    // I could have fixed that upstream, but with 78 PR, it may
    // take some time to be merged
    if (!value) {
      return ''
    }

    if (value < 1000) {
      return value + 'B'
    }
    return numeral(value).format('0.0b')
  })

  Vue.filter('mbfilesize', (value) => {
    if (!value) {
      return ''
    }

    value = value * 1000 * 1000

    if (value < 1000) {
      return value + 'B'
    }
    return numeral(value).format('0.0b')
  })

  Vue.mixin({
    methods: {
      roleAllows(minRole, role) {
        if (minRole === 'member')
          return role === 'member' || role === 'admin' || role === 'owner'
        if (minRole === 'admin') return role === 'admin' || role === 'owner'
        if (minRole === 'owner') return role === 'owner'

        return true
      }
    }
  })
}
