import * as api from '@utils/api.js'

const state = {
  version: '1.0.220906',
  versionName: 'Public Beta',
  pwaInstallPrompt: null,
  isAppConfigLoaded: false,
  mixpanelEvents: {},
  context: 'default',
  contextDarkModeOverride: null,
  browserAction: 'default',
  browserReturnAction: 'return_asset'
}

const getters = {
  context(state) {
    return state.context
  },

  browserAction(state) {
    return state.browserAction
  },

  browserReturnAction(state) {
    return state.browserReturnAction
  },

  contextDarkModeOverride(state) {
    return state.contextDarkModeOverride
  },

  version(state) {
    return state.version
  },

  appVersionName(state) {
    return state.versionName
  },

  pwaInstallAvailable(state) {
    return state.pwaInstallPrompt !== null
  },

  pwaInstallPrompt(state) {
    return state.pwaInstallPrompt
  },

  isAppConfigLoaded(state) {
    return state.isAppConfigLoaded
  },

  mixpanelEvents(state) {
    return state.mixpanelEvents
  }
}

const mutations = {
  SET_PWA_INSTALL_PROMPT(state, prompt) {
    state.pwaInstallPrompt = prompt
  },

  SET_APP_CONFIG_LOADED(state, value) {
    state.isAppConfigLoaded = value
  },

  SET_MIXPANEL_EVENTS(state, events) {
    state.mixpanelEvents = events
  },

  SET_APP_CONTEXT(state, context) {
    state.context = context
  },

  SET_APP_CONTEXT_DARK_MODE_OVERRIDE(state, mode) {
    state.contextDarkModeOverride = mode
  },

  SET_BROWSER_ACTION(state, mode) {
    state.browserAction = mode
  },

  SET_BROWSER_RETURN_ACTION(state, mode) {
    state.browserReturnAction = mode
  }
}

const actions = {
  setContext({ commit }, context) {
    commit('SET_APP_CONTEXT', context)
  },

  setContextDarkModeOverride({ commit }, mode) {
    commit('SET_APP_CONTEXT_DARK_MODE_OVERRIDE', mode)
  },

  setBrowserAction({ commit }, mode) {
    commit('SET_BROWSER_ACTION', mode)
  },

  setBrowserReturnAction({ commit }, mode) {
    commit('SET_BROWSER_RETURN_ACTION', mode)
  },

  handlePWABeforeInstallPrompt({ commit }, { prompt }) {
    commit('SET_PWA_INSTALL_PROMPT', prompt)
  },

  loadAppConfig({ commit }) {
    return api
      .rawApiGetCall('api/app-config')
      .then((response) => {
        const data = response.data.data
        commit('SET_MIXPANEL_EVENTS', data.mixpanel_events)
        commit('SET_APP_CONFIG_LOADED', true)
        return data
      })
      .catch(() => {
        commit('SET_APP_CONFIG_LOADED', false)
      })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
