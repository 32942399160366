import store from '../store'

export default [
  {
    path: '/',
    name: 'home',
    meta: {
      isAuthenticated: false,
      isAuthBounce: true
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ '@views/AuthRequired.vue'),
    beforeEnter: (to, from, next) => {
      let authedAndReady = store.getters['auth/isAuthedAndReady']
      if (authedAndReady && store.getters['firstPath'] !== null) {
        next(store.getters['firstPath'])
      }
      next('/dashboard')
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@views/Dashboard.vue'),
    meta: {},

    beforeEnter: (to, from, next) => {
      next()
    }
  },
  {
    path: '/onboarding',
    name: 'user-onboarding',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@views/UserOnboarding.vue'),
    meta: {},

    beforeEnter: (to, from, next) => {
      if (store.getters['doesUserNeedOnboarding']) {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
  },

  {
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@views/Error.vue'),
    meta: {
      isAuthenticated: false
    }
  },

  {
    path: '/auth-required',
    name: 'auth-required',
    component: () =>
      import(/* webpackChunkName: "auth" */ '@views/AuthRequired.vue'),
    meta: {
      isAuthenticated: false
    }
  },

  {
    path: '/auth-error',
    name: 'auth-error',
    component: () =>
      import(/* webpackChunkName: "auth" */ '@views/AuthError.vue'),
    meta: {
      isAuthenticated: false
    }
  }
]
