import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import axios from 'axios'

import { getInstance } from '@/auth/authWrapper.js'

import UploadType from '@src/enums/uploadType'
import UploadState from '@src/enums/uploadState'

export default class PrevizDirectUpload {
  /**
   * Responsible for handling uploads using Uppy to S3.
   * @param {UploadType} uploadType - The type of upload to perform
   */
  constructor(uploadType = UploadType.STANDARD) {
    this.uploadType = uploadType

    this.uppy = new Uppy({
      id: 'uppy-direct-upload'
    })

    switch (this.uploadType) {
      case UploadType.STANDARD:
        this.uppy.use(XHRUpload, {
          endpoint: '', // To be set later in the _prepareSelfSignedUpload method
          method: 'put',
          formData: false
        })
        break
      case UploadType.MULTIPART:
        this.uppy.use(AwsS3Multipart, {
          companionUrl: process.env.VUE_APP_SERVER_API_BASE
        })
    }
  }

  /**
   * Register events for Uppy and stores the actions in the class
   * so the unregister method can be called later.
   */
  _registerUppyEvents() {
    this.uppyOnSuccess = (file, response) => {
      // Set the return data based on the upload type
      if (file.xhrUpload) {
        this.returnData.url = file.xhrUpload.endpoint
      } else if (file.s3Multipart) {
        this.returnData.url = response.uploadURL
        this.returnData.key = file.s3Multipart.key
        // This isn't great, but it's the easiest way to get the UUID
        this.returnData.uuid = file.s3Multipart.key.replace('tmp/', '')
      }

      this.returnData.extension = file.extension
      this.returnData.state = UploadState.UPLOADED
      this.options.progress(1)
    }

    this.uppyOnError = (file, error) => {
      this.options.progress(1)
    }

    this.uppyOnProgress = (file, progress) => {
      this.options.progress(
        progress.bytesTotal > 0
          ? progress.bytesUploaded / progress.bytesTotal
          : 0
      )
    }

    // One time events
    this.uppy.once('upload-success', this.uppyOnSuccess)
    this.uppy.once('upload-error', this.uppyOnError)

    // Every time events
    this.uppy.on('upload-progress', this.uppyOnProgress)
  }

  /**
   * Unregister events for Uppy
   */
  _unregisterUppyEvents() {
    this.uppy.off('upload-progress', this.uppyOnProgress)
  }

  /**
   * Checks if the upload has finished, and stops it
   * if the user has cancelled it.
   * @param {string} uppyFileId - The UUID of the file (from Uppy)
   */
  async _waitForUploadToFinish(uppyFileId) {
    // NOTE: Not super crazy about this solution, open to suggestions
    // on how else to alert this class that the user wishes to cancel,
    // without refactoring how the upper level store is implemented.
    while (this.returnData.state !== UploadState.UPLOADED) {
      // Small timeout to prevent spamming
      await new Promise((resolve) => setTimeout(resolve, 100))

      // Cancel the upload if the user has cancelled it
      if (this.options.uploadElement.shouldCancelUpload) {
        this.uppy.removeFile(uppyFileId)
        this.returnData.state = UploadState.CANCELLED
        break
      }
    }
  }

  async _prepareSelfSignedUpload(file, uppyFileId) {
    const authService = getInstance()
    const token = await authService.getTokenSilently()

    const response = await axios.post(
      '/api/uploads/signed-storage-url',
      {
        bucket: this.options.bucket || '',
        content_type: this.options.contentType || file.type,
        expires: this.options.expires || '',
        visibility: this.options.visibility || ''
      },
      {
        baseURL: process.env.VUE_APP_SERVER_API_BASE,
        headers: {
          Authorization: 'Bearer ' + token,
          Accept: 'application/json'
        },
        ...this.options.options
      }
    )

    // Set the endpoint as returned from the backend
    this.uppy.setFileState(uppyFileId, {
      xhrUpload: {
        ...this.uppy.getFile(uppyFileId).xhrUpload,
        endpoint: response.data.data.url
      }
    })

    // Merge in the return data from the backend
    this.returnData = {
      ...this.returnData,
      ...response.data.data
    }
  }

  /**
   * Store a file in S3 and return its UUID, key, and other information.
   */
  async store(file, options = {}) {
    this.returnData = {
      state: UploadState.UPLOADING
    }
    this.options = options

    // let headers = response.data.headers

    // if ('Host' in headers) {
    //   delete headers.Host
    // }

    if (typeof this.options.progress === 'undefined') {
      this.options.progress = () => {}
    }

    // Add the file to Uppy
    const uppyFileId = this.uppy.addFile({
      name: file.name,
      type: file.type,
      data: file
    })

    if (this.uploadType === UploadType.STANDARD) {
      await this._prepareSelfSignedUpload(file, uppyFileId)
    }

    // Register events and start upload
    this._registerUppyEvents()
    this.uppy.upload()

    // Wait for the upload to finish
    await this._waitForUploadToFinish(uppyFileId)

    // Clean up
    this.uppy.removeFile(uppyFileId)
    this._unregisterUppyEvents()

    // Check for errors and throw if necessary
    // Note: We are only currently only uploading one file at a time,
    // which is why we blindly throw the first error we find
    const uppyState = this.uppy.getState()
    if (uppyState.info.length > 0 && uppyState.info[0].type === 'error') {
      throw new Error(uppyState.info[0].message)
    }

    return this.returnData
  }
}

// export default PrevizDirectUpload()
// module.exports = new PrevizDirectUpload()
