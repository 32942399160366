/* eslint no-undef: 2 */
import * as api from '@utils/api.js'

const state = {
  user: null,
  error: null,

  tokens: [],

  firstProject: null,
  firstPath: null
}

const getters = {
  firstProject(state) {
    return state.firstProject
  },

  firstPath(state) {
    return state.firstPath
  },

  user: (state, rootGetters) => {
    if (rootGetters['auth/isAuthed']) return state.user
    return null
  },

  userRemoteId: (state, rootGetters) => {
    if (rootGetters['auth/isAuthed']) return state.user.remote_id
    return null
  },

  error: (state) => {
    return state.error
  },

  isDeveloper: (state, getters) => {
    if (
      state.user &&
      process.env.VUE_APP_SPACES_DEVELOPERS &&
      process.env.VUE_APP_SPACES_DEVELOPERS !== ''
    ) {
      return process.env.VUE_APP_SPACES_DEVELOPERS.includes(
        '|' + state.user.email + '|'
      )
    }
    return false
  },

  doesUserNeedPasswordUpdate: (state, getters) => {
    if (getters.user == null) return false
    return getters.user.needs_password
  },

  doesUserNeedProfileUpdate: (state, getters) => {
    if (getters.user == null) return false
    return getters.user.needs_profile
  },

  doesUserNeedEmailValidation: (state, getters) => {
    if (getters.user == null) return false
    return getters.user.needs_email_validation
  },

  doesUserNeedOnboarding: (state, getters) => {
    if (getters.user == null) return false
    return getters.user.needs_onboarding
  },

  doesUserNeedOnboardingProject: (state, getters) => {
    if (getters.user == null) return false
    return getters.user.needs_onboarding_project
  }
}

const actions = {
  setFirst({ commit }, { project, path }) {
    commit('SET_FIRST', { project: project, path: path })
  },

  clearError({ commit }) {
    commit('clearError')
  },

  // A temporary fix for the first run setup problem
  // Dev only. To remove very soon. (ie, this week)
  forceCreateUserDev({ commit }, payload) {
    let uri = 'api/dev/create-user'
    return api.rawApiPostCall(uri, payload)
  },

  setNeedsOnboarding({ commit, rootGetters }, payload) {
    const user = rootGetters.user

    return api
      .updateUser(user.id, {
        needs_onboarding: payload
      })
      .then((response) => {
        commit('setCurrentUser', { user: response.data })
      })
      .catch((error) => {
        console.error(error)
      })
  },

  fetchAuthCheck({ dispatch, rootGetters }) {
    let uri = 'api/auth-check'
    return api.rawApiGetCall(uri).then(() => {
      if (!rootGetters['app/isAppConfigLoaded'])
        dispatch('app/loadAppConfig', null, { root: true })
    })
  },

  fetchUser({ commit }) {
    let uri = 'api/me'

    return api
      .rawApiGetCall(uri)
      .then((response) => {
        let user = response.data.data
        commit('clearError')
        commit('setCurrentUser', { user })

        commit(
          'preferences/STORE_DARK_MODE_CHOICE',
          user.preference_lightmode,
          {
            root: true
          }
        )
        commit('preferences/STORE_TIMEZONE_CHOICE', user.preference_timezone, {
          root: true
        })

        commit('preferences/STORE_LANGUAGE_CHOICE', user.preference_language, {
          root: true
        })

        return user
      })
      .catch((err) => {
        commit('setError', { err })
        return false
      })
  }
}

const mutations = {
  SET_FIRST(state, { project, path }) {
    state.firstProject = project
    state.firstPath = path
  },

  setError(state, { err }) {
    state.error = err
  },

  clearError(state) {
    state.error = null
  },

  setCurrentUser(state, { user }) {
    state.user = user
  },

  userSetPasswordUpdated(state) {
    state.user.needs_password = false
  },

  userSetProfileUpdated(state) {
    state.user.needs_profile = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
