export default class KeyboardComponent {
  static UNSET = new KeyboardComponent('UNSET')
  static ASSET_COLLECTION = new KeyboardComponent('ASSET_COLLECTION')
  static ASSET_TREE = new KeyboardComponent('ASSET_TREE')
  static ASSET_LIST = new KeyboardComponent('ASSET_LIST')
  static ASSET_GRID = new KeyboardComponent('ASSET_GRID')
  static ASSET_VIEW = new KeyboardComponent('ASSET_VIEW')
  static ASSET_EMPTY = new KeyboardComponent('ASSET_EMPTY')
  static ASSET_DELETE_MODAL = new KeyboardComponent('ASSET_DELETE_MODAL')
  static SHARE_CONFIRM_MODAL = new KeyboardComponent('SHARE_CONFIRM_MODAL')
  static DRIVE_BLOCK_FOOTER = new KeyboardComponent('DRIVE_BLOCK_FOOTER')

  constructor(name) {
    this.name = name
  }
}
