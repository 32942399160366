/* global Spark */

/**
 * Initialize the Spark form extension points.
 */

Spark.forms = {}

/**
 * Load the SparkForms helper class.
 */
require('./form')

/**
 * Define the SparkFormError collection class.
 */
require('./errors')
