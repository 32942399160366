<template>
  <div id="app" class="flex flex-col h-full overflow-hidden bg-gray-100 dark:bg-[#101010]" :class="pageClass">
    <Notificatons />
    <slot name="nav"></slot>
    <slot></slot>
    <OverlayContainer />
    <!-- <AppLoader /> -->
    <RouterView />
  </div>
</template>

<script>
import { appDarkMode } from '@mixins/app-dark-mode'
import { appLanguage } from '@mixins/app-language'
import { commandBar } from '@mixins/command-bar'
import { forceBodyDarkOnEmbed } from './utils/misc'
const AppLoader = () => import('@components/AppLoader')
const OverlayContainer = () => import('@components/OverlayContainer')
const NprogressContainer = () =>
  import(/* webpackChunkName: 'nav' */ 'vue-nprogress/src/NprogressContainer')

// import { Notificatons as notifications } from '@components/Notifications'

const Notificatons = () => import('@components/Notifications')

forceBodyDarkOnEmbed()

export default {
  name: 'app',

  components: {
    AppLoader,
    OverlayContainer,
    NprogressContainer,
    Notificatons
  },

  mixins: [appDarkMode, appLanguage, commandBar],

  mounted() {
    console.log(
      'Welcome to Disguise Cloud. Running in :' +
        process.env.VUE_APP_MODE +
        ' mode'
    )

    this.updateBaseClass()
    this.attachSystemChangeListener()
  },

  created() {
    this.registerGlobalWSCallbacks()
  },

  computed: {
    containerClass() {
      if (this.isPublic) return ''
      return 'site-grid'
    },

    pageClass() {
      if (this.$route.meta.pageClass) return this.$route.meta.pageClass
      return ''
    },

    isPublic() {
      return this.$route.meta.isPublic === true
    },

    navBarComponent() {
      if (this.isPublic) return 'nav-bar-public'
      return 'nav-bar'
    },

    isOnlyGuest() {
      if (this.user === null) return false
      return this.user.is_only_guest
    },

    user() {
      return this.$store.getters.user
    },

    teams() {
      return this.$store.getters['teams/teams']
    },

    navVisible() {
      if (this.$route.query.hideNav === 'y') return false
      return true
    },

    routeMeta() {
      return this.$route
    },

    isAuthedAndReady() {
      return this.$store.getters['auth/isAuthedAndReady']
    }
  },

  watch: {
    teams() {
      this.registerGlobalWSCallbacks()
    },
    user(state) {
      if (state != null) {
        this.bootCommandBar(this.user.id)
      } else {
        this.shutdownCommandBar()
      }
    },

    isAuthedAndReady: {
      handler() {
        window.EchoInit()
      },
      immediate: true
    }
  },

  methods: {
    noop() {},

    registerGlobalWSCallbacks() {
      // let that = this
      // this.teams.forEach(function (team) {
      //   window.Echo.private('team.' + team.id)
      //     .listen('.projectsUpdated', (e) => {
      //       that.$store.dispatch('projectsList/reload')
      //     })
      // })
    }
  }
}
</script>
