import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome'

import {
  faPenSquare,
  faPenCircle,
  faLifeRing,
  faCrosshairs,
  faHandPointer,
  faGameBoardAlt,
  faGameBoard,
  faRulerTriangle,
  faTh,
  faRightToBracket,
  faBookmark,
  faLink,
  faCode,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
  faArrows,
  faSyncAlt,
  faBackwardStep,
  faForwardStep,
  faAsterisk,
  faForward,
  faBackward,
  faRepeat,
  faRepeat1,
  faLock,
  faLockOpen,
  faEllipsis,
  faEllipsisV,
  faEllipsisStroke,
  faCaretUp,
  faCaretRight,
  faCaretDown,
  faCaretLeft,
  faCaretSquareDown,
  faAngleUp,
  faAngleDown,
  faLevelDownAlt,
  faPlay,
  faStop,
  faPause,
  faWindowMaximize,
  faWindowRestore,
  faSquare,
  faVolumeOff,
  faVolumeDown,
  faVolume,
  faVolumeHigh,
  faVolumeMute,
  faExpand,
  faCompress,
  faGear,
  faShare,
  faArchive,
  faExchangeAlt,
  faUsersCog,
  faPlane,
  faArrowUp,
  faArrowLeft,
  faArrowRight,
  faArrowDown,
  faArrowDownToLine,
  faArrowToRight,
  faArrowAltFromLeft,
  faArrowAltFromRight,
  faArrowAltToLeft,
  faArrowAltToRight,
  faPlus,
  faUserPlus,
  faPlusSquare,
  faMinusSquare,
  faMinusCircle,
  faPenToSquare,
  faUser,
  faUserSlash,
  faSignInAlt,
  faSignOutAlt,
  faBell,
  faCircleQuestion,
  faQuestionSquare,
  faQuestion,
  faPencil,
  faVideo,
  faImage,
  faPhotoVideo,
  faCameraMovie,
  faFile,
  faFiles,
  faClone,
  faFileAudio,
  faFileVideo,
  faFileArchive,
  faFileImage,
  faFileImport,
  faFileArrowUp,
  faFilm,
  faCube,
  faLayerGroup,
  faUnlock,
  faEye,
  faTrashCan,
  faComment,
  faCommentAltLines,
  faTriangleExclamation,
  faCircleExclamation,
  faSpinner,
  faCircleNotch,
  faCheck,
  faCircleCheck,
  faUsers,
  faUserFriends,
  faCircle,
  faExpandWide,
  faCompressWide,
  faGripLinesVertical,
  faGripHorizontal,
  faList,
  faIndent,
  faLightbulbOn,
  faLightbulb,
  faEyeSlash,
  faBars,
  faXmark,
  faClock,
  faComments,
  faCommentsAlt,
  faFolder,
  faFolders,
  faFolderOpen,
  faFolderPlus,
  faFolderArrowDown,
  faCreditCardFront,
  faMagnifyingGlass,
  faLayerPlus,
  faUpload,
  faLevelDown,
  faLevelUp,
  faDeleteLeft,
  faDiamond,
  faCircleInfo,
  faSort,
  faDotCircle,
  faBolt,
  faInboxIn,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faHouse,
  faExchange,
  faBuilding,
  faCar,
  faCalendarAlt,
  faCalendar,
  faFlag,
  faGlobeAmericas,
  faPresentation,
  faCubes,
  faAlignLeft,
  faTheaterMasks,
  faTv,
  faMusic,
  faGlobe,
  faGlobeEurope,
  faCopy,
  faCreditCard,
  faMousePointer,
  faExpandArrows,
  faSlidersVSquare,
  faMap,
  faArrowUpRightFromSquare,
  faWaveform,
  faEnvelope,
  faBorderAll,
  faCloudUpload,
  faFileMusic,
  faUserCircle,
  faTag,
  faTags,
  faShuffle,
  faThLarge,
  faSparkles,
  faBarsStaggered,
  faProjector,
  faPaperclip,
  faNewspaper,
  faKeyboard,
  faScrubber,
  faCalendarPen,
  faHardDrive,
  faMessage,
  faReply,
  faICursor,
  faSave,
  faFloppyDisk,
  faHashtag,
  faBan,
  faFilter,
  faPen,
  faPaintbrushFine,
  faEraser,
  faPalette,
  faSliders,
  faSlidersV,
  faUndo,
  faRedo,
  faImagePolaroid,
  faPenPaintbrush,
  faRectangleVerticalHistory,
  faArrowUp91,
  faArrowDown91,
  faArrowDown19,
  faBug,
  faUserGroup
} from '@fortawesome/pro-solid-svg-icons'

import {
  faDropbox,
  faGoogleDrive,
  faDev,
  faSlack
} from '@fortawesome/free-brands-svg-icons'

import {
  faDiamond as farDiamond,
  faAngleDoubleLeft as farAngleDoubleLeft,
  faCube as farCube,
  faFrown as farFrown,
  faGlobe as farGlobe,
  faCheckSquare as farCheckSquare,
  faCreditCard as farCreditCard,
  faSlidersVSquare as farSlidersVSquare,
  faHeart as farHeart,
  faKeyboard as farKeyboard,
  faClock as farClock,
  faImagePolaroid as farImagePolaroid
} from '@fortawesome/pro-regular-svg-icons'

import { faSpinnerThird as fadSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'

library.add(
  faPenSquare,
  faPenCircle,
  faLifeRing,
  faFileMusic,
  faArrowUpRightFromSquare,
  faCrosshairs,
  faHandPointer,
  faGameBoardAlt,
  faGameBoard,
  faRulerTriangle,
  faTh,
  faRightToBracket,
  faBookmark,
  faLink,
  faCode,
  faGlobe,
  faGlobeEurope,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
  faArrows,
  faSyncAlt,
  faForward,
  faBackward,
  faForwardStep,
  faBackwardStep,
  faRepeat,
  faRepeat1,
  faSlack,
  faDev,
  faHouse,
  faLock,
  faLockOpen,
  faEllipsis,
  faEllipsisV,
  faEllipsisStroke,
  faCaretUp,
  faCaretRight,
  faCaretDown,
  faCaretLeft,
  faCaretSquareDown,
  faAngleUp,
  faAngleDown,
  faLevelDownAlt,
  faPlay,
  faStop,
  faPause,
  faWindowMaximize,
  faWindowRestore,
  faSquare,
  faVolumeOff,
  faVolumeDown,
  faVolume,
  faVolumeHigh,
  faVolumeMute,
  faExpand,
  faCompress,
  faGear,
  faShare,
  faArchive,
  faExchangeAlt,
  faUsersCog,
  faPlane,
  faArrowUp,
  faArrowLeft,
  faArrowRight,
  faArrowDown,
  faArrowDownToLine,
  faArrowToRight,
  faArrowAltFromLeft,
  faArrowAltFromRight,
  faArrowAltToLeft,
  faArrowAltToRight,
  faPlus,
  faUserPlus,
  faPlusSquare,
  faMinusSquare,
  faMinusCircle,
  faPenToSquare,
  faUser,
  faUserSlash,
  faSignInAlt,
  faSignOutAlt,
  faBell,
  faCircleQuestion,
  faQuestionSquare,
  faQuestion,
  faPencil,
  faVideo,
  faImage,
  faPhotoVideo,
  faCameraMovie,
  faFile,
  faFiles,
  faClone,
  faFileAudio,
  faFileVideo,
  faFileArchive,
  faFileImage,
  faFileImport,
  faFileArrowUp,
  faFilm,
  faCube,
  faLayerGroup,
  faUserGroup,
  faUnlock,
  faEye,
  faCalendarPen,
  faEyeSlash,
  faTrashCan,
  faComment,
  faCommentAltLines,
  faTriangleExclamation,
  faCircleExclamation,
  faSpinner,
  fadSpinnerThird,
  faCircleNotch,
  faCheck,
  faAsterisk,
  faCircleCheck,
  farCheckSquare,
  farCreditCard,
  faUsers,
  faUserFriends,
  faExpandWide,
  faCompressWide,
  faGripLinesVertical,
  faGripHorizontal,
  faList,
  faIndent,
  faLightbulbOn,
  faLightbulb,
  faBars,
  faXmark,
  faCircle,
  faClock,
  faComments,
  faCommentsAlt,
  faFolder,
  faFolders,
  faFolderOpen,
  faFolderPlus,
  faFolderArrowDown,
  faCreditCardFront,
  faMagnifyingGlass,
  faLayerPlus,
  faUpload,
  faDropbox,
  faGoogleDrive,
  faLevelDown,
  faLevelUp,
  faDeleteLeft,
  faDiamond,
  farDiamond,
  farAngleDoubleLeft,
  farCube,
  farFrown,
  farGlobe,
  faCircleInfo,
  faSort,
  faDotCircle,
  faBolt,
  faInboxIn,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faExchange,
  faBuilding,
  faCar,
  faCalendarAlt,
  faCalendar,
  faFlag,
  faGlobeAmericas,
  faPresentation,
  faCubes,
  faAlignLeft,
  faTheaterMasks,
  faTv,
  faMusic,
  faCopy,
  faCreditCard,
  faMousePointer,
  faExpandArrows,
  faSlidersVSquare,
  farSlidersVSquare,
  farHeart,
  farKeyboard,
  faMap,
  faWaveform,
  faEnvelope,
  faBorderAll,
  faCloudUpload,
  faUserCircle,
  faTag,
  faTags,
  faShuffle,
  faThLarge,
  faSparkles,
  faBarsStaggered,
  faProjector,
  faPaperclip,
  faNewspaper,
  faKeyboard,
  faScrubber,
  faCalendarPen,
  faHardDrive,
  faMessage,
  farClock,
  faReply,
  faICursor,
  faSave,
  faFloppyDisk,
  faHashtag,
  faBan,
  faFilter,
  faPen,
  faPaintbrushFine,
  faEraser,
  faPalette,
  faSliders,
  faSlidersV,
  faUndo,
  faRedo,
  faImagePolaroid,
  farImagePolaroid,
  faPenPaintbrush,
  faRectangleVerticalHistory,
  faArrowUp91,
  faArrowDown91,
  faArrowDown19,
  faBug
)

Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
