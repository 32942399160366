import { cloneDeep } from 'lodash'

import KeyboardComponent from '@src/enums/keyboardComponent'
import KeyboardBindingLevel from '@src/enums/keyboardBindingLevel'

class KeyboardBinding {
  constructor() {
    // Global and local bindings are separate
    this.globalBindings = {
      Escape: {
        userMapping: ['Escape'],
        callback: () => {
          // console.log('Escape')
        }
      },
      Slash: {
        userMapping: ['Slash'],
        callback: () => {
          // console.log('Slash')
        }
      },
      KeyU: {
        userMapping: ['KeyU'],
        callback: () => {
          // console.log('KeyU')
        }
      }
    }

    this.bindings = {
      Enter: {
        userMapping: ['Enter'],
        callback: () => {
          // console.log('Enter')
        }
      },
      Delete: {
        userMapping: ['Delete'],
        callback: () => {
          // console.log('Delete')
        }
      },
      Backspace: {
        userMapping: ['Backspace'],
        callback: () => {
          // console.log('Backspace')
        }
      },
      Space: {
        userMapping: ['Space'],
        callback: () => {
          // console.log('Space')
        }
      },
      ArrowUp: {
        userMapping: ['ArrowUp'],
        callback: () => {
          // console.log('ArrowUp')
        }
      },
      ArrowDown: {
        userMapping: ['ArrowDown'],
        callback: () => {
          // console.log('ArrowDown')
        }
      },
      ArrowLeft: {
        userMapping: ['ArrowLeft'],
        callback: () => {
          // console.log('ArrowLeft')
        }
      },
      ArrowRight: {
        userMapping: ['ArrowRight'],
        callback: () => {
          // console.log('ArrowRight')
        }
      },
      KeyJ: {
        userMapping: ['KeyJ'],
        callback: () => {
          // console.log('KeyJ')
        }
      },
      KeyK: {
        userMapping: ['KeyK'],
        callback: () => {
          // console.log('KeyK')
        }
      }
    }

    this.KEYS_TO_ALLOW_DURING_INPUT = ['Escape']
    this.INPUT_ELEMENTS = ['INPUT', 'TEXTAREA']

    // Used for unbinding/resetting the keyboard bindings
    this.defaultGlobalBindings = cloneDeep(this.globalBindings)
    this.defaultBindings = cloneDeep(this.bindings)

    this.activeComponent = KeyboardComponent.UNSET

    window.addEventListener('keydown', this._handleKeyDown.bind(this))
  }

  _handleKeyDown(event) {
    // Merge the global and local bindings
    const combinedBindings = Object.assign(
      {},
      this.globalBindings,
      this.bindings
    )

    // If the key is in the combined bindings, then call the callback
    const binding =
      combinedBindings[
        Object.keys(combinedBindings).find((key) => {
          return combinedBindings[key].userMapping.includes(event.code)
        })
      ]

    // Dude, the user is trying to type, don't interfere with
    // their typing experience (ignore our callback)
    const activeElement = document.activeElement

    // Bind the callback only if the element is not an input, unless the key is allowed
    if (
      binding &&
      (!this.INPUT_ELEMENTS.includes(activeElement.tagName) ||
        this.KEYS_TO_ALLOW_DURING_INPUT.includes(event.code))
    ) {
      binding.callback()
      event.preventDefault()
    }
  }

  /**
   * Maps one or more user keys to a hard key
   * @param {String} key - The hard key code to bind
   * @param {String|Array} userMapping - The user mapping(s) (the key codes) to bind to the key
   * @param {KeyboardBindingLevel} level - The level of the binding, default is GLOBAL
   */
  setUserMapping({ key, userMap, bindingLevel = KeyboardBindingLevel.GLOBAL }) {
    // Convenience conversion if the user is just mapping a single key / passed as a string
    if (!Array.isArray(userMap)) {
      userMap = [userMap]
    }

    // Prepare to check if the key is already bound
    const combinedBindings = Object.assign(
      {},
      this.globalBindings,
      this.bindings
    )
    delete combinedBindings[key] // We don't check ourselves, we map a set at the end instead

    // Check if any of the userMappings are already bound to another key
    const alreadyBoundKeys = userMap.filter((userMapping) => {
      let isKeyAlreadyBound = false
      Object.keys(combinedBindings).forEach((key) => {
        isKeyAlreadyBound = combinedBindings[key].userMapping.includes(
          userMapping
        )
          ? true
          : isKeyAlreadyBound
      })
      return isKeyAlreadyBound
    })

    const binding =
      bindingLevel !== KeyboardBindingLevel.GLOBAL
        ? this.bindings
        : this.globalBindings

    // For now, just remove the already bound keys from the userMapping
    // and remove duplicates from the userMapping now that we know it's not already bound
    binding[key].userMapping = [
      ...new Set(
        userMap.filter((userMapping) => {
          const isNotAlreadyBound = !alreadyBoundKeys.includes(userMapping)
          if (!isNotAlreadyBound) {
            // Yell at the developer for now (but we probably want to alert the user
            // when they can create their own mapping)
            console.warn(
              `The key '${userMapping}' is already bound to '${key}'`
            )
          }
          return isNotAlreadyBound
        })
      )
    ]
  }

  resetAllUserMappings() {
    this.resetGlobalBindingUserMappings()
    this.resetBindingUserMappings()
  }

  resetGlobalBindingUserMappings() {
    Object.keys(this.bindings).forEach((key) => {
      this.bindings[key].userMapping = this.defaultBindings[key].userMapping
    })
  }

  resetBindingUserMappings() {
    Object.keys(this.globalBindings).forEach((key) => {
      this.globalBindings[key].userMapping =
        this.defaultGlobalBindings[key].userMapping
    })
  }

  bindGlobalKey({ key, callback }) {
    this.globalBindings[key].callback = callback
  }

  bindKey({ key, callback, component }) {
    // First check if this component is the active component, if not, then
    // we know focus is changing so we rebind all keys to defaults
    if (this.activeComponent !== component) {
      this.bindings = cloneDeep(this.defaultBindings)
    }

    this.activeComponent = component
    this.bindings[key].callback = callback
  }

  unbindGlobalKey(key) {
    this.globalBindings[key].callback = this.defaultGlobalBindings[key].callback
  }

  unbindComponentKeyboardKeys(component) {
    // If the component is the active component, then we need to unbind all keys
    // as the component is requesting to be deactivated
    if (component === this.activeComponent) {
      this.activeComponent = KeyboardComponent.UNSET
      Object.keys(this.bindings).forEach((key) => {
        this.bindings[key].callback = this.defaultBindings[key].callback
      })
    }
  }
}

export default new KeyboardBinding()
