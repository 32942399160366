import * as api from '@utils/api.js'
import { updateInListByKey } from '@utils/misc.js'
import sortBy from 'lodash/sortBy'

const state = {
  teams: [],
  teamsLoaded: false,
  teamsLoading: false,
  teamsLoadingError: false
}

const getters = {
  teams(state) {
    return sortBy(state.teams, ['team_order'])
  },

  members: (state) => (id) => {
    return []
  },

  getTeamById: (state) => (id) => {
    let found = state.teams.find((row) => {
      return row.id === id || row.slug === id
    })

    if (found === undefined) return null
    return found
  },

  teamsLoading(state) {
    return state.teamsLoading
  },

  teamsLoadingError(state) {
    return state.teamsLoadingError
  },

  teamsLoaded(state) {
    return state.teamsLoaded
  },

  roles(state) {
    return state.teams.map(function (team) {
      return team.team_role
    })
  }
}

const actions = {
  setTeams({ commit, state }, { teams }) {
    commit('setTeams', { state, teams })
  },
  addTeam({ commit }, team) {
    commit('addTeam', team)
  },
  addTeamMembers({ commit }, members) {
    commit('addTeamMembers', members)
  },
  setTeamsLoading({ commit }) {
    commit('setTeamsLoading')
  },
  setTeamsLoadingError({ commit }) {
    commit('setTeamsLoadingError')
  },
  setTeamsLoaded({ commit }) {
    commit('setTeamsLoaded')
  },
  removeTeam({ commit }, { id }) {
    commit('removeTeam', id)
  },

  fetchTeamsSoft({ dispatch, state }) {
    if (state.teamsLoaded !== true) dispatch('fetchTeams')
  },

  fetchTeams({ dispatch, commit }) {
    dispatch('setTeamsLoading')

    return api
      .fetchTeams()
      .then((response) => {
        response.data.forEach((row) => {
          commit('addTeam', row.data)
        })
        dispatch('setTeamsLoaded')
        return response.data
      })
      .catch(() => {
        dispatch('setTeamsLoadingError')
      })
  }

}

const mutations = {
  setTeams(state, { teams }) {
    state.teams = teams
    state.teamsLoaded = true
    state.teamsLoading = false
    state.teamsLoadingError = false
  },
  setTeamsLoaded(state) {
    state.teamsLoaded = true
    state.teamsLoading = false
    state.teamsLoadingError = false
  },
  setTeamsLoading(state) {
    state.teamsLoaded = false
    state.teamsLoading = true
    state.teamsLoadingError = false
  },
  setTeamsLoadingError(state) {
    state.teamsLoaded = false
    state.teamsLoading = false
    state.teamsLoadingError = true
  },
  addTeam(state, team) {
    if (team.members !== undefined) {
      delete team.members
    }
    state.teams = updateInListByKey(state.teams, 'id', team.id, team)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
