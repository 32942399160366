import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
import { authGuard } from './auth'

import AuthRoutes from './routes/auth.js'
import PagesRoutes from './routes/pages.js'
import MissingRoutes from './routes/missing.js'
import ProjectRoutes from './routes/project.js'
import ShareRoutes from './routes/share.js'
import DownloadRoutes from './routes/download.js'

Vue.use(VueRouter)

const appRoutes = [
  ...PagesRoutes,
  ...AuthRoutes,
  ...ProjectRoutes,
  ...ShareRoutes,
  ...DownloadRoutes,
  ...MissingRoutes // Missing should always be last
]

let routes = appRoutes

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.params.slug !== undefined && to.meta.canSetAsFirst === true) {
    store.dispatch('setFirst', {
      project: to.params.slug,
      path: to.fullPath
    })
  }

  // We want to clear the children responses when we navigate between
  // folders and views within a project/embed page; this prevents repeated
  // API calls on the same page from several components requesting the same data
  if (from.path.startsWith('/p/') || from.path.startsWith('/embed/')) {
    store.commit('assets/clearChildrenResponses')
  }

  if (to.matched.some((record) => record.meta.isAuthenticated !== false)) {
    authGuard(to, from, next)
  } else {
    next()
  }
})

export default router
