const NotFoundPage = () =>
import(/* webpackChunkName: "missing" */ '@views/404')
const MissingDrive = () =>
import(/* webpackChunkName: "missing" */ '@views/MissingDrive')

export default [
  {
    path: '/missing-drive',
    name: '404-Drive',
    component: MissingDrive
  },
  {
    path: '*',
    name: '404',
    component: NotFoundPage
  }
]
