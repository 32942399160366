import sortBy from 'lodash/sortBy'
import Set from 'set'

export function getFeatureGateState(project, featureKey) {
  if (project === null) return false
  if (!project.hasOwnProperty('features')) return false
  if (!project.features.hasOwnProperty(featureKey)) return false
  if (project.features[featureKey] === true) return true
  return false
}

export function setDocumentTitle(title) {
  let main = 'Disguise Cloud Spaces'
  if (title === null || title === false || title === '') document.title = main
  document.title = title + ' :: DC Spaces'
}

export function generateUuid() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11)
    .replace(/[018]/g, (c) =>
      (
        c ^
        (window.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    )
    .toUpperCase()
}

/**
 * Recursively finds a key in an object
 * @param {Object} obj - The object to search
 * @param {String} key - The key to find
 * @returns {Object} The object with the key or undefined if not found
 */
export function findKeyInObject(obj, key) {
  if (obj.hasOwnProperty(key)) return obj[key]
  for (let k in obj) {
    if (obj.hasOwnProperty(k)) {
      let v = obj[k]
      if (typeof v === 'object') {
        let result = findKeyInObject(v, key)
        if (result !== null) return result
      }
    }
  }
  return null
}

export function findInListByKey(list, key, value) {
  let ret = null
  list.forEach(function (el) {
    if (el[key] === value) {
      ret = el
    }
  })
  return ret
}

export function findManyInListByKey(list, key, value) {
  let ret = []
  list.forEach(function (el) {
    if (el[key] === value) {
      ret.push(el)
    }
  })
  return ret
}

export function replaceInListByKey(list, key, value, updated) {
  list.forEach(function (el, index, arr) {
    if (el[key] === value) {
      arr[index] = updated
    }
  })
  return list
}

export function updateInListByKey(list, key, value, updated, withNull = false) {
  let found = false
  list.forEach(function (el, index) {
    if (el[key] === value) {
      found = index
    }
  })

  if (found === false) {
    list.push(updated)
  } else {
    let original = list[found]

    // loop over the properties singly
    // and only update those that are non-null
    for (var property in updated) {
      if (updated.hasOwnProperty(property)) {
        if (updated[property] !== null || withNull) {
          original[property] = updated[property]
        }
      }
    }

    list.splice(found, 1, original)
  }

  return list
}

export function debounceToFrame(cb, root = window) {
  let requestId = null
  return function () {
    var cbArgs = arguments
    if (requestId !== null) {
      root.cancelAnimationFrame(requestId)
      requestId = null
    }
    requestId = root.requestAnimationFrame(() => {
      cb.apply(null, cbArgs)
      requestId = null
    })
  }
}

export function noopPromise(retVal) {
  return new Promise((resolve, reject) => {
    resolve(retVal)
  })
}

export function fileUploadKey(file) {
  return file.name + ':' + file.size
}

export function defaultSceneForProject(project) {
  if (project.scenes.length === 0) return null
  let sorted = sortBy(project.scenes, 'order_column')
  return sorted.slice(0)[0]
}

export function defaultTrackForScene(scene) {
  if (scene.tracks.length === 0) return null
  let sorted = sortBy(scene.tracks, 'order_column')
  return sorted.slice(0)[0]
}

export function timeToString(seconds, showHours) {
  showHours = showHours ? true : seconds >= 3600

  function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n)
  }

  if (!isNumber(seconds)) {
    return ''
  }

  let sec = Math.floor(seconds)
  let millisec = Math.floor((seconds % 1) * 1000)
  var date = new Date(null)
  date.setSeconds(sec, millisec)

  let left = showHours ? 11 : 14
  let size = showHours ? 8 : 5
  return date.toISOString().substr(left, size)
}

export function clamp(v, minV, maxV) {
  if (minV === null && maxV === null) {
    return v
  }

  if (minV === null) {
    return Math.min(v, maxV)
  }

  if (maxV === null) {
    return Math.max(v, minV)
  }

  return Math.max(Math.min(v, maxV), minV)
}

export function uploadingScenes(project) {
  let scenes = project.uploadingScenes
  let ret = []
  for (let i in scenes) {
    ret.push(scenes[i])
  }
  return sortBy(ret, 'startUploadDate')
}

export function uploadingScenePercent(scene) {
  if (scene.total === null) {
    return ''
  }
  let v = (scene.loaded / scene.total) * 100
  return Math.round(v) + '%'
}

export function areSameItem(it1, it2) {
  let id1 = it1 ? it1.id : null
  let id2 = it2 ? it2.id : null
  return id1 === id2
}

function PropertyProxy(obj, propertyName, propertyProxyName) {
  let ret = {}
  Object.defineProperty(ret, propertyProxyName, {
    get: function () {
      return obj[propertyName]
    },
    set: function (value) {
      obj[propertyName] = value
    }
  })
  return ret
}

export function forEachObjectWithAssetInProject(project, cb) {
  project.scenes.forEach((scene) => {
    scene.tracks.forEach((track) => {
      track.layers.forEach((layer) => {
        layer.modules.forEach((module) => {
          cb(module)
        })
      })
    })

    scene.meshes.forEach((mesh) => {
      cb(PropertyProxy(mesh, 'defaultMap', 'asset'))
      cb(PropertyProxy(mesh, 'alphaMap', 'asset'))
      cb(PropertyProxy(mesh, 'pixelMap', 'asset'))
    })
  })
}

export function buildTransparentDragGhost() {
  let ret = document.createElement('span')
  ret.style.position = 'absolute'
  ret.style.display = 'block'
  ret.style.top = '0px'
  ret.style.left = '0px'
  ret.style.width = '0px'
  ret.style.height = '0px'
  return ret
}

export function checkProjectId(project, id) {
  let key = typeof id === 'string' ? 'slug' : 'id'
  if (key === 'slug' && id.length > 16) {
    key = 'id' // UUIDs are detected as strings, but are ids
  }
  return project[key] === id
}

export function checkTeamId(team, id) {
  let key = typeof id === 'string' ? 'slug' : 'id'
  if (key === 'slug' && id.length > 16) {
    key = 'id' // UUIDs are detected as strings, but are ids
  }
  return team[key] === id
}
/*
Compares an iterable property between two objects and call callbacks for new, deleted and updated items

EXAMPLE:
let objA = {
    aList: [{id: 0, v: 'A'}, {id: 1, v: 'B'}]
}
let objB = {
    aList: [{id: 1, v: 'C'}, {id: 2, v: 'D'}]
}

diff({
    orig: objA,
    update: objB,
    prop: 'aList'
    key: 'id',
    onNew: (objA, newItem) => {console.log('New item: ' + newItem.id);},
    onDelete: (objA, deleteItem) => {console.log('Delete item: ' + deletedItem.id);},
    onUpdate: (objA, oldItem, newItem) => {console.log('Updated item: ' + oldItem.id + ', old v: ' + oldItem.v + ', 'new v: ' + newItem.v);},
});

OUPUT:
Delete item: 0
New item: 2
Updated item: 1, old v: B, new v: C

NOTE: update each call for every item existing if both `orig[key]` and `update[key]`, diff does not actually check if items are identical or not
*/
function buildStructures(iterable, key) {
  let map = {}
  let set = new Set()
  iterable.forEach((item) => {
    let itemKey = item[key]
    map[itemKey] = item
    set.add(itemKey)
  })
  return { map, set }
}

export function diff({ orig, update, prop, key, onNew, onDelete, onUpdate }) {
  let origProp = typeof prop === 'string' ? prop : prop.orig
  let updateProp = typeof prop === 'string' ? prop : prop.update
  let origKey = typeof key === 'string' ? key : key.orig
  let updateKey = typeof key === 'string' ? key : key.update

  onNew = onNew || Function.prototype
  onDelete = onDelete || Function.prototype
  onUpdate = onUpdate || Function.prototype

  let origSt = buildStructures(orig[origProp], origKey)
  let updateSt = buildStructures(update[updateProp], updateKey)

  let deletedKeys = origSt.set.difference(updateSt.set).intersect(origSt.set)
  let newKeys = origSt.set.difference(updateSt.set).intersect(updateSt.set)
  let updatedKeys = origSt.set.intersect(updateSt.set)

  deletedKeys.get().forEach((key) => {
    onDelete(orig, origSt.map[key])
  })

  newKeys.get().forEach((key) => {
    onNew(orig, updateSt.map[key])
  })

  updatedKeys.get().forEach((key) => {
    onUpdate(orig, origSt.map[key], updateSt.map[key])
  })
}

export function diffList({ orig, update, key, onNew, onDelete, onUpdate }) {
  onNew = onNew || Function.prototype
  onDelete = onDelete || Function.prototype
  onUpdate = onUpdate || Function.prototype

  let origSt = buildStructures(orig, key)
  let updateSt = buildStructures(update, key)

  let deletedKeys = origSt.set.difference(updateSt.set).intersect(origSt.set)
  let newKeys = origSt.set.difference(updateSt.set).intersect(updateSt.set)
  let updatedKeys = origSt.set.intersect(updateSt.set)

  deletedKeys.get().forEach((key) => {
    onDelete(origSt.map[key])
  })

  newKeys.get().forEach((key) => {
    onNew(updateSt.map[key])
  })

  updatedKeys.get().forEach((key) => {
    onUpdate(origSt.map[key], updateSt.map[key])
  })
}

export function shallowCopy(orig, update, excepts) {
  excepts = excepts || []
  let updateKeys = new Set(Object.keys(update))
  let exceptsKeys = new Set(excepts)
  let keys = updateKeys.difference(exceptsKeys)

  keys.get().forEach((key) => {
    orig[key] = update[key]
  })
}

export function capturePromiseRejection(reason) {
  // XXX @charlesfleche 2017-10-06
  // The proper way to solve this would be to register
  // a global callback for unhandled Promise
  // rejection and return a Promise.reject(reason)
  // However:
  //
  // 1. Our current Promise library explicitly warn
  // against this in production for performance
  // reasons
  // https://www.npmjs.com/package/promise#unhandled-rejections
  //
  // 2. The native Promise window unhandled-rejection
  // event is not activated by default on Firefox
  //
  // As such, we have to explicitly call Sentry
  // code in the rejection callback

  let msg =
    reason && reason.message ? reason.message : 'Unknown Promise rejection'
  console.error(msg)
  if (window.Sentry) {
    window.Sentry.captureException(new Error(msg), { extra: reason })
  }
}

export function logError({ error, message, alert }) {
  if (window.Sentry && message) {
    window.Sentry.captureMessage(message)
  }
  if (window.alert && alert) {
    window.alert(alert)
  }
  if (message) {
    console.error(message)
  }
  if (error) {
    console.error(error)
  }
}

// Normalize to duration if duration is passed
// If not keeps raw TimeRanges values
export function timeRangesToObject(timeRanges, duration) {
  duration = isNaN(parseFloat(duration)) ? 1 : duration

  let ret = []

  for (let i = 0; i < timeRanges.length; ++i) {
    ret.push({
      start: timeRanges.start(i) / duration,
      end: timeRanges.end(i) / duration
    })
  }

  return ret
}

export function ensureProperty(obj, key, value) {
  if (!obj.hasOwnProperty(key) || obj[key] === null) {
    obj[key] = value
  }
}

export function CallBacks() {
  return {
    _callBacks: [],

    register(cb) {
      this._callBacks.push(cb)
    },
    unregister(cb) {
      let idx = this._callBacks.indexOf(cb)
      this._callBacks.splice(idx, 1)
    },
    unregisterAll() {
      this._callBacks = []
    },
    call() {
      this._callBacks.forEach((cb) => {
        cb.apply(this, arguments)
      })
    }
  }
}

/**
 * This is gross and I hate it. But here's the reason.
 * Firefox sucks.
 *
 * On Firefox, it renders the body before Vue and Tailwind load,
 * so no matter what we do, the body will be white for a few seconds.
 * This forces the body to be dark for 30 seconds, which is enough
 * time for Vue and Tailwind to load.
 *
 * Issue does not happen on Chrome.
 *
 * Probably happens on Safari too, because Safari sucks.
 */
export function forceBodyDarkOnEmbed() {
  // Can't rely on the router just yet
  const url = new URL(window.location.href)

  if (url.pathname.includes('embed')) {
    // Can't rely on Tailwind either
    const bodyElement = document.querySelector('body')
    const previousBackground = bodyElement.style.background
    bodyElement.style.background = '#2c3136'

    setTimeout(() => {
      // Clean up after ourselves
      bodyElement.style.background = previousBackground
    }, 30 * 1000)
  }
}
