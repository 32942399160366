/* eslint no-undef: 1 */
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import Cookies from 'js-cookie'

import projects from './modules/projects'
import settings from './modules/settings'
import user from './modules/user'
import team from './modules/team'
import teams from './modules/teams'
import members from './modules/members'
import shares from './modules/shares'
import shared from './modules/shared'
import downloads from './modules/downloads'

import projectsList from './modules/projectsList'
import archivedProjects from './modules/archivedProjects'
import project from './modules/project'
import app from './modules/app'
import auth from './modules/auth'
import preferences from './modules/preferences'
import featureFlags from './modules/featureFlags'
import assets from './modules/assets'
import comments from './modules/comments'
import feedback from './modules/feedback'
import tags from './modules/tags'
import fileTypes from './modules/fileTypes'
import sequencer from './modules/sequencer'

Vue.use(Vuex)

// initial state
let initialState = {
  user: user.state,
  shared: shared.state
}

const vuexCookie = new VuexPersistence({
  key: process.env.VUE_APP_SPACES_VUEX_LOCALKEY, // The key to store the state on in the storage provider.
  restoreState: (key, storage) => Cookies.getJSON(key),
  saveState: (key, state, storage) =>
    Cookies.set(key, state, {
      expires: 1
    }),
  modules: ['auth', 'preferences', 'shared'] // only save auth, preferences and shared modules
})

const vuexLocalStorage = new VuexPersistence({
  key: process.env.VUE_APP_SPACES_VUEX_LOCALKEY, // The key to store the state on in the storage provider.
  storage: window.localStorage,
  reducer: (state) => ({
    user: state.user,
    shared: state.shared
  })
})

let store = new Vuex.Store({
  modules: {
    projects,
    settings,
    user,
    preferences,
    featureFlags,
    assets,
    team,
    teams,
    members,
    shares,
    shared,
    downloads,
    projectsList,
    archivedProjects,
    project,
    app,
    auth,
    comments,
    fileTypes,
    feedback,
    sequencer,
    tags
  },
  mutations: {
    resetVuexAll(state) {
      Object.keys(state).forEach((key) => {
        if (initialState[key] !== undefined) {
          Object.assign(state[key], initialState[key])
        }
      })
    }
  },
  namespaced: true,
  strict: process.env.VUE_APP_MODE === 'local',
  plugins: [vuexCookie.plugin, vuexLocalStorage.plugin]
})

// Ensure exceptions thrown in Promise in Vuex actions are reported on the console
// This is probably not the right way to do it:
// 1. The vuex:error message is undocumented (see vuex store.js registerAction for it's definition
// 2. store._devtoolHook doesn't exist in production
// I've (@charlesfleche) posted a question on SO about the best way to solve this
// https://stackoverflow.com/questions/43149716/catch-all-exception-in-vue-action
if (store._devtoolHook) {
  store._devtoolHook.on('vuex:error', (err) => {
    console.error(err)
  })
}

export default store
