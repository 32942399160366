export default class UploadState {
  static UNSET = new UploadState('UNSET')
  static UPLOADING = new UploadState('UPLOADING')
  static UPLOADED = new UploadState('UPLOADED')
  static CANCELLED = new UploadState('CANCELLED')
  static ABORTED = new UploadState('ABORTED')
  static ERROR = new UploadState('ERROR')

  constructor(name) {
    this.name = name
  }
}
